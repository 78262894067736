@import '../components/tooltip-block';

.main[data-pathname='/pay'] {
  .top-drop-name {
    display: none;
  }
}

.top-drop-wrap {
  display: flex;
  // padding: 6px 0;
  // min-height: 152px;
  overflow: hidden;

  @media(max-width: 560px) {
    padding: 0;
  }
}

.drop-btns {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 82px;
  position: relative;
  top: 0;
  //margin-right: 2px;
  z-index: 7;

  @media(max-width: 1024px) {
    display: none;
  }

  @media(max-width: 560px) {
    top: 6px;
  }
  a {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 40px;
    background-color: rgba(5, 11, 22, 1);
    transition: all 0.5s;
    position: relative;

    &:hover {
      background-color: #363744;
      .badge {
        opacity: 1;
      }
    }
  }

  .drop-best {
    border-radius: 0 8px 0 0;
    //opacity: 0.8;

    &-wrap {
      background: #050b16;
      border-radius: 0 8px 0 0;
      height: 39px;
      width: 39px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // &:hover {
    //   opacity: 1;
    // }

    .icon {
      font-size: 24px;
      line-height: 24px;
      background: linear-gradient(0deg, #90939a -25%, #4c515c 125%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &:hover {
      .drop-best-wrap {
        background: linear-gradient(
                        90deg,
                        rgba(50, 60, 153, 0.32) 0%,
                        rgba(21, 29, 107, 0.32) 100%
        );
      }

      .icon {
        background: linear-gradient(0deg, #597bca -25%, #94b5ff 125%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    &.active {
      // background: rgb(255, 198, 0);
      // background: -moz-linear-gradient(
      //                 left,
      //                 rgba(255, 198, 0, 1) 20%,
      //                 rgba(255, 198, 0, 1) 100%
      // );
      // background: -webkit-linear-gradient(
      //                 left,
      //                 rgba(255, 198, 0, 1) 20%,
      //                 rgba(255, 198, 0, 1) 100%
      // );
      // background: linear-gradient(
      //                 to right,
      //                 rgba(255, 198, 0, 1) 20%,
      //                 rgba(255, 198, 0, 1) 100%
      // );
      // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc600', endColorstr='#ffc600', GradientType=1);

      .drop-best-wrap {
        background: #181115;
      }

      .icon {
        background: linear-gradient(315deg, #ff9c0e -0.01%, #ffd800 100.01%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      img {
        opacity: 0;

        &.hover {
          opacity: 1;
        }
      }

      &.better-drop {
        border-radius: 0 0 8px 0;

        .drop-best-wrap {
          border-radius: 0 0 8px 0;
        }
      }
    }

    &.active {
      &:before {
        @extend %before-after;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 2;
      }
    }
  }

  .better-drop {
    border-radius: 0 0 8px 0;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s;

    &.hover {
      opacity: 0;
    }
  }

  .badge {
    position: absolute;
    top: 50%;
    left: 33px;
    transform: translateY(-50%);
    height: 20px;
    padding: 0 8px;
    color: #fff;
    background-color: rgb(81, 86, 102);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.5s;
    pointer-events: none;
  }
}

.top-drop {
  white-space: nowrap;
  font-size: 0;
  position: relative;
  border: 0;
  display: flex;
  margin-top: -1px;
  //margin-bottom: 58px;
  z-index: 5;

  // @media(max-width: 560px) {
  //   padding: 6px 0 35px 0;
  //   margin-bottom: -35px;
  //   overflow-x: auto;
  // }
  &::-webkit-scrollbar {
    @media(max-width: 560px) {
      width: 0;
    }
  }
  & > :first-child {
    //width: 188px;
    min-width: 188px;
    // width: 192px;
    // min-width: 192px;
    // margin: 1px 4px 0 2px;
    margin-right: 2px;
    box-shadow: none;


    .top_drop_item_view_hover {
      padding-left: 20px;
      padding-right: 20px;
    }

    .top_drop_item_view_hover_case {
      width: 68px;
      height: 68px;   
    }

    @media(max-width: 1024px) {
      min-width: 96px;

      .top_drop_item_view_image {
        right: 16px;
      }
    }

    // .top-drop-item-first {
    //   position: absolute;
    //   top: -2px;
    //   left: -2px;
    //   width: calc(100% + 4px);
    //   height: calc(100% + 4px);
    //   background: rgb(255, 0, 0);
    //   background: url(../images/top-drop-bg-first.svg) no-repeat 0 0;
    //   z-index: -1;
    //   transition: background 0.3s ease-in-out;

    //   @media(max-width: 480px) {
    //     display: none;
    //   }
    // }

    // .drop-img {
    //   padding-left: 50px;

    //   img {
    //     width: 130px;
    //     height: 100px;
    //     margin-top: -22px;
    //   }
    // }

    // .top-drop-item-hover {
    //   padding: 17px 0 0 0;
    //   margin-top: 0 !important;
    //   justify-content: flex-end;
    //   opacity: 1;
    // }

    // .top-drop-item-name {
    //   margin-bottom: 35px;
    //   transition: all 0.3s;
    // }

    // .top-drop-item-ava,
    // .top-drop-item-case {
    //   opacity: 0;
    //   transition: all 0.5s;
    // }

    // &:hover {
    //   .top-drop-item-name {
    //     margin-bottom: 18px;
    //   }

    //   .top-drop-item-ava,
    //   .top-drop-item-case {
    //     opacity: 1;
    //   }

    //   .top-drop-item-first {
    //     background: url(../images/top-drop-bg-first-hover.svg) no-repeat 0 0;
    //   }
    // }
  }

  &-item {
    color: $white;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    min-width: 128px;
    width: 128px;
    height: 80px;
    margin-right: 2px;
    background-image: linear-gradient(
                    to bottom,
                    rgba(176, 195, 217, 0.12),
                    rgba(176, 195, 217, 0.5)
    );
    background-color: #120d2d;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.5);
    vertical-align: top;
    position: relative;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 12px;

    @media(max-width: 480px) {
      height: 70px;
    }
    &:before {
      @extend %before-after;
      background-image: linear-gradient(
                      to bottom,
                      rgba(176, 195, 217, 0.32),
                      #b0c3d9
      );
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 12px;
    }

    &:after {
      @extend %before-after;
      top: 3px;
      left: 3px;
      background-color: rgba(35, 41, 51, 0.4);
      background-image: radial-gradient(
                      circle 95px at 50.14% 129.77%,
                      rgba(94, 152, 217, 0.5) 0,
                      rgba(94, 152, 217, 0.3) 50%,
                      transparent 100%
      );
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border-radius: 9px;
      z-index: 2;
    }

    &-name {
      width: 100%;
      color: #f7c0fe;
      font-size: 12px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.8);
      text-shadow: 0px 2px 3px #13072c;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0 0 18px 0;
      padding: 0 10px;
      font-family: 'Roboto Condensed', sans-serif;
    }

    &-ago {
      font-size: 12px;
      line-height: 14px;
      color: rgba(213, 185, 255, 0.8);
      opacity: 0.8;
      text-shadow: 0px 2px 3px #13072c;
      margin: 0 0 4px 0;
      padding: 0 10px;
      width: 100%;
      font-family: 'Roboto Condensed', sans-serif;
    }

    &-hover {
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      padding: 35px 0 0 0;
      opacity: 0;
      transition: all 0.5s;
      text-align: left;
      z-index: 4;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .top-drop-item-hover {
      pointer-events: none;
      text-align: left;
    }

    &:hover {
      .drop-img {
        margin-top: -10px;
      }

      .top-drop-item-hover {
        opacity: 1;
        z-index: 3;
        pointer-events: all;
        margin-top: -16px;
      }
    }

    .honey {
      position: absolute;
      top: 3px;
      left: 3px;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      pointer-events: none;
      background-image: url(../images/bg-live-item-1.svg);
      background-color: #120d2d;
      border-radius: 9px;
      z-index: 2;
    }

    &[data-rar='4b69ff'] {
      background-image: linear-gradient(
                      to bottom,
                      rgba(75, 105, 255, 0.12),
                      rgba(75, 105, 255, 0.5)
      );

      &:before {
        background-image: linear-gradient(
                        to bottom,
                        rgba(75, 105, 255, 0.32),
                        #4b69ff
        );
      }

      &:after {
        background-image: radial-gradient(
                        circle 95px at 50.14% 129.77%,
                        rgba(75, 105, 255, 0.5) 0,
                        rgba(75, 105, 255, 0.3) 50%,
                        transparent 100%
        );
      }

      .honey {
        background-image: url(../images/bg-live-item-2.svg);
      }
    }

    &[data-rar='5e98d9'] {
      background-image: linear-gradient(
                      to bottom,
                      rgba(94, 152, 217, 0.12),
                      rgba(94, 152, 217, 0.5)
      );

      &:before {
        background-image: linear-gradient(
                        to bottom,
                        rgba(94, 152, 217, 0.32),
                        #5e98d9
        );
      }

      &:after {
        background-image: radial-gradient(
                        circle 95px at 50.14% 129.77%,
                        rgba(94, 152, 217, 0.5) 0,
                        rgba(94, 152, 217, 0.3) 50%,
                        transparent 100%
        );
      }

      .honey {
        background-image: url(../images/bg-live-item-6.svg);
      }
    }

    &[data-rar='d32ce6'] {
      background-image: linear-gradient(
                      to bottom,
                      rgba(211, 44, 230, 0.12),
                      rgba(211, 44, 230, 0.5)
      );

      &:before {
        background-image: linear-gradient(
                        to bottom,
                        rgba(211, 44, 230, 0.32),
                        #d32ce6
        );
      }

      &:after {
        background-image: radial-gradient(
                        circle 95px at 50.14% 129.77%,
                        rgba(211, 44, 230, 0.5),
                        rgba(211, 44, 230, 0.3) 50%,
                        transparent 100%
        );
      }

      .honey {
        background-image: url(../images/bg-live-item-4.svg);
      }
    }

    &[data-rar='8847ff'] {
      background-image: linear-gradient(
                      to bottom,
                      rgba(136, 71, 255, 0.12),
                      rgba(136, 71, 255, 0.5)
      );

      &:before {
        background-image: linear-gradient(
                        to bottom,
                        rgba(136, 71, 255, 0.32),
                        #8847ff
        );
      }

      &:after {
        background-image: radial-gradient(
                        circle 95px at 50.14% 129.77%,
                        rgba(136, 71, 255, 0.5) 0,
                        rgba(136, 71, 255, 0.3) 50%,
                        transparent 100%
        );
      }

      .honey {
        background-image: url(../images/bg-live-item-5.svg);
      }
    }

    &[data-rar='eb4b4b'] {
      background-image: linear-gradient(
                      to bottom,
                      rgba(235, 75, 75, 0.12),
                      rgba(235, 75, 75, 0.5)
      );

      &:before {
        background-image: linear-gradient(
                        to bottom,
                        rgba(235, 75, 75, 0.32),
                        #eb4b4b
        );
      }

      &:after {
        background-image: radial-gradient(
                        circle 95px at 50.14% 129.77%,
                        rgba(235, 75, 75, 0.5) 0,
                        rgba(235, 75, 75, 0.3) 50%,
                        transparent 100%
        );
      }

      .honey {
        background-image: url(../images/bg-live-item-7.svg);
      }
    }

    &[data-rar='ffd700'] {
      background-image: linear-gradient(
                      to bottom,
                      rgba(255, 215, 0, 0.12),
                      rgba(255, 215, 0, 0.5)
      );

      &:before {
        background-image: linear-gradient(
                        to bottom,
                        rgba(255, 215, 0, 0.32),
                        #ffd700
        );
      }

      &:after {
        background-image: radial-gradient(
                        circle 95px at 50.14% 129.77%,
                        rgba(255, 215, 0, 0.5) 0,
                        rgba(255, 215, 0, 0.3) 50%,
                        transparent 100%
        );
      }

      .honey {
        background-image: url(../images/bg-live-item-7.svg);
      }
    }

    .top-drop-game-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      transition: 0.3s;

      &-tooltipe {
        position: absolute;
        background: linear-gradient(90deg, #380b9d 0%, #490475 100%);
        border-radius: 4px;
        top: calc(100% - 2px);
        left: calc(100% - 3px);
        padding: 2px 8px;
        font-size: 14px;
        line-height: 16px;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s;
      }

      &:hover {
        .top-drop-game-icon-tooltipe {
          opacity: 1;
          top: calc(100% - 1px);
          left: calc(100% - 2px);
        }
      }
    }

    &-other-contracts {
      background: linear-gradient(90deg, #2e0675 0%, #5b17d8 100%);
      border: 2px solid #8847ff;

      .top-drop-game-icon-tooltipe {
        background: linear-gradient(90deg, #7d36ff 0%, #3900a1 100%);
      }

      &:hover {
        border: 3px solid #8847ff;
      }
    }

    &-other-upgrade {
      background: linear-gradient(90deg, #4f0857 0%, #b111c4 100%);
      border: 2px solid #d32ce6;
      background-size: 32px 32px;

      .top-drop-game-icon-tooltipe {
        background: linear-gradient(270deg, #4f0857 0%, #b111c4 100%);
      }

      &:hover {
        border: 3px solid #d32ce6;
      }
    }

    &-other-event {
      background: linear-gradient(90deg, #120014 0%, #4a0053 100%);
      border: 2px solid #8847ff;

      &:before {
        @extend %before-after;
        background: url(../images/icon-event.svg) no-repeat 50% 50%;
        width: 13px;
        height: 13px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 1px;
      }

      &:hover {
        border: 3px solid #8847ff;
      }
    }

    &-other-give {
      background: linear-gradient(270deg, #13072c -25%, #220462 125%);
      border: 2px solid #2e008d;

      .icon {
        color: #ff2c3b;
        text-shadow: 0px 0px 12px #ff2f2f;
        background: #fef2c8;
        border-radius: 50%;
        font-size: 17px;
        margin: 0 -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
      }

      &:hover {
        border: 3px solid #2e008d;
      }
    }

    &[data-type='1'] {
      .top-drop-game-icon {
        background: linear-gradient(90deg, #2e0675 0%, #5b17d8 100%);
        border: 2px solid #8847ff;

        .top-drop-game-icon-tooltipe {
          background: linear-gradient(90deg, #7d36ff 0%, #3900a1 100%);
        }

        &:hover {
          border: 3px solid #8847ff;
        }
      }
    }

    &[data-type='2'] {
      .top-drop-game-icon {
        background: linear-gradient(90deg, #4f0857 0%, #b111c4 100%);
        border: 2px solid #d32ce6;
        background-size: 32px 32px;

        .top-drop-game-icon-tooltipe {
          background: linear-gradient(270deg, #4f0857 0%, #b111c4 100%);
        }

        &:hover {
          border: 3px solid #d32ce6;
        }
      }
    }

    &[data-type='3'] {
      .top-drop-game-icon {
        background: linear-gradient(90deg, #120014 0%, #4a0053 100%);
        border: 2px solid #8847ff;

        &:before {
          @extend %before-after;
          background: url(../images/icon-event.svg) no-repeat 50% 50%;
          width: 13px;
          height: 13px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-top: 1px;
        }

        &:hover {
          border: 3px solid #8847ff;
        }
      }
    }

    &[data-type='4'],
    &[data-type='5'] {
      .top-drop-game-icon {
        background: linear-gradient(270deg, #13072c -25%, #220462 125%);
        border: 2px solid #2e008d;

        .icon {
          color: #ff2c3b;
          text-shadow: 0px 0px 12px #ff2f2f;
          background: #fef2c8;
          border-radius: 50%;
          font-size: 17px;
          margin: 0 -1px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 14px;
          height: 14px;
        }

        &:hover {
          border: 3px solid #2e008d;
        }
      }
    }

    &-ava,
    &-case {
      width: 48px;
      height: 48px;
      background: rgb(63, 0, 97);
      background: -moz-linear-gradient(
                      top,
                      rgba(63, 0, 97, 1) 0%,
                      rgba(49, 0, 77, 1) 100%
      );
      background: -webkit-linear-gradient(
                      top,
                      rgba(63, 0, 97, 1) 0%,
                      rgba(49, 0, 77, 1) 100%
      );
      background: linear-gradient(
                      to bottom,
                      rgba(63, 0, 97, 1) 0%,
                      rgba(49, 0, 77, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3f0061', endColorstr='#31004d', GradientType=0);
      padding: 2px;
      border-radius: 50%;
      position: relative;
      transition: padding 0.3s ease-in-out;
      margin: 0 6px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 44px;
        height: 44px;
        object-fit: cover;
        transition: all 0.3s;
      }

      &:hover {
        padding: 4px;

        img {
          width: 42px;
          height: 42px;
        }

        .tooltip-block {
          opacity: 1;
        }
      }
    }

    &-ava {
      img {
        border-radius: 50%;
      }
    }

    &[data-type='0'] {
      .top-drop-item-case {
        background: #7d36ff;
        background: -moz-linear-gradient(top, #7d36ff 0%, #3900a1 100%);
        background: -webkit-linear-gradient(top, #7d36ff 0%, #3900a1 100%);
        background: linear-gradient(to bottom, #7d36ff 0%, #3900a1 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7d36ff', endColorstr='#3900a1', GradientType=0);

        &:before {
          @extend %before-after;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          background: linear-gradient(0deg, #5119b8 0%, #8847ff 100%);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }

        &:after {
          @extend %before-after;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          background: rgba(0, 0, 0, 0.64);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }

        img {
          position: relative;
          z-index: 2;
        }

        &:hover {
          &:before,
          &:after {
            width: calc(100% - 6px);
            height: calc(100% - 6px);
          }
        }
      }
    }

    &[data-type='3'] {
      .top-drop-item-case {
        transition: all 0.3s;
        box-shadow: inset 0 0 0 2px #8847ff;
        background: url(../images/live-drop-event-bg.png) no-repeat 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 59px;
          height: 43px;
          border-radius: 0;
        }

        &:hover {
          box-shadow: inset 0 0 0 4px #8847ff;

          img {
            width: 59px;
            height: 43px;
          }
        }
      }
    }

    &[data-type='1'] {
      .top-drop-item-case {
        background: linear-gradient(90deg, #380694 0%, #1a0443 100%);
        border: 2px solid #8847ff;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: 30px;
        }

        .tooltip-block {
          background: linear-gradient(90deg, #7d36ff 0%, #3900a1 100%);
        }

        &:hover {
          border: 4px solid #8847ff;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    &[data-type='2'] {
      .top-drop-item-case {
        background: linear-gradient(90deg, #380694 0%, #1a0443 100%);
        border: 2px solid #d32ce6;
        position: relative;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: 30px;
        }

        &:hover {
          border: 4px solid #d32ce6;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .tooltip-block {
          background: linear-gradient(270deg, #4f0857 0%, #b111c4 100%);
        }
      }
    }

    &[data-type='4'],
    &[data-type='5'] {
      .top-drop-item-case {
        background: linear-gradient(90deg, #380b9d 0%, #490475 100%);
        border: 2px solid #8847ff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;

        img {
          width: 59px;
          height: 45px;
        }

        &:hover {
          border: 4px solid #8847ff;

          img {
            width: 59px;
            height: 45px;
          }
        }
      }
    }
  }

  .drop-img {
    width: 100%;
    height: 62px;
    margin-top: 5px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: all 0.5s;
    position: relative;
    z-index: 3;
    background-size: 80%;
    -webkit-filter: drop-shadow(-2px 9px 4px rgba(0, 0, 0, 0.65));
    filter: drop-shadow(-2px 9px 4px rgba(0, 0, 0, 0.65));
    display: block;

    img {
      width: 92px;
      height: 72px;
      object-fit: contain;
      display: block;
      margin: -5px auto 0 auto;
    }
  }

  &:hover {
    z-index: 6;
  }

  .drop-chance {
    position: absolute;
    transition: all 0.5s;
    left: 10px;
    bottom: 10px;
    background: rgba(63, 0, 71, 0.4);
    border: 2px solid rgba(211, 44, 230, 0.16);
    border-radius: 4px;
    padding: 0 2px;
    height: 16px;
    font-size: 12px;
    line-height: 12px;
    color: #f7c0fe;
    z-index: 4;
    font-family: 'Roboto Condensed', sans-serif;
  }
}

.top-drop-item {
  .stattrack {
    position: absolute;
    bottom: 11px;
    right: 11px;
    z-index: 4;
    height: 16px;
    line-height: 12px;
    font-size: 10px;
    background: #ffc64b;
    border: 2px solid #fffc00;
    color: #050b16;
    border-radius: 2px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
  }

  .in-case-cost {
    opacity: 0;
    transition: all 0.5s;
    transform: translateY(10px);
  }

  &.status-1 {
    .in-case-cost {
      background: linear-gradient(90deg, #26003e 0, #230170);
      box-shadow: 0 0 0 4px rgba(40, 4, 161, 0.24);
    }
  }

  &.status-2 {
    .in-case-cost {
      background: linear-gradient(90deg, #005700 0, #089408);
      box-shadow: 0 0 0 2px #06c50c;
    }
  }

  &.status-3 {
    .in-case-cost {
      background: linear-gradient(90deg, #002e87 0, #0652ff);
      box-shadow: 0 0 0 2px #6595ff;
    }
  }

  &.status-4 {
    .in-case-cost {
      background: linear-gradient(to right, #2e0675 0%, #5b17d8 100%);
      box-shadow: 0px 0px 0px 2px #8847ff;
    }
  }

  &.status-5 {
    .in-case-cost {
      background: linear-gradient(90deg, #4f0857 0, #b111c4);
      box-shadow: 0 0 0 2px #d32ce6;
    }
  }

  &.status-6 {
    .in-case-cost {
      background: linear-gradient(90deg, #490475 0%, #380b9d 100%), #c4c4c4;
      box-shadow: 0 0 0 2px #6804a1;
    }
  }

  &:hover {
    .in-case-cost {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.in-case-item .other-labels-item {
  position: absolute;
  top: 100%;
  left: 7px;
  display: flex;
  align-items: center;
  margin-top: -14px;
  z-index: 4;

  .stattrack {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-right: 4px;
  }

  .drop-chance {
    position: relative;
    left: 0;
    bottom: 0;
    height: 22px;
    background: #3f0047;
    padding: 0 2px;
    display: flex;
    align-items: center;
    color: #f7c0fe;
    border: 2px solid rgba(211, 44, 230, 0.24);
    border-radius: 4px;
  }
}

.top-drop-item[data-type='1'] .top-drop-game-icon,
.top-drop-item-other-contracts {
  width: 20px;
  height: 20px;
}

.top-drop-item[data-type='2'] .top-drop-game-icon,
.top-drop-item-other-upgrade {
  width: 20px;
  height: 20px;
  background-size: 28px 28px;
}

.top-drop-title {
  position: absolute;
  top: 90px;
  left: 240px;
  z-index: 6;
  height: 24px;
  background: #080809;
  border: 1px solid #591b78;
  border-radius: 0px 0px 12px 12px;
  padding: 0 6px 0 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: 0.35px;
  color: #f5412e;
  text-transform: uppercase;
  @include flex(false, center, center, false);

  @media(max-width: 1200px) {
    display: none;
  }

  &-wrap {
    @include flex(false, center, center, false);

    & > :nth-child(2) {
      margin-left: 16px;
      position: relative;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: rgba(230, 155, 255, 0.4);
      text-transform: none;
      @include flex(false, center, false, false);

      @include before() {
        width: 1px;
        height: 16px;
        background: radial-gradient(50% 50% at 50% 50%, #B0C3D9 0%, rgba(176, 195, 217, 0.0001) 100%);
        mix-blend-mode: normal;
        opacity: .2;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
      }

      .icon {
        margin-right: 4px;
        font-size: 16px;
      }
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid #ac0302;
      margin-right: 5px;
      animation: pulseopacity 0.9s ease infinite;
      display: block;
      background: #4a140e;
    }
  }
}

@keyframes pulseopacity {
  0% {
    opacity: 1;
  }
  40%,
  60% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.top-drop-name {
  position: absolute;
  top: 91px;
  left: 10px;
  width: 226px;
  height: 28px;
  background: url(../images/top-drop-icon.svg) no-repeat 0 0;
  padding: 1px 34px 4px 38px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: -0.5px -0.5px 0 #eb4b4b, -0.5px -0.5px 0 #eb4b4b,
  -0.5px 0.5px 0 #eb4b4b, 0.5px 0.5px 0 #eb4b4b, 0px 0px 14.2439px #eb4b4b;
  z-index: 6;
  pointer-events: none;

  @media(max-width: 1200px) {
    background: url(../images/top-drop-icon-mob.svg) no-repeat 0 0;
  }
  @media(max-width: 1200px) {
    display: none;
  }
}
