.main:not([data-pathname='/pay']) {
  .Toastify {
    .absolute {
      position: absolute;
      margin-top: 250px;
    }
  }
}

.Toastify {
  position: relative;
  overflow-x: clip;

  @media(max-width: 992px) {
    display: none !important;
  }
  .Toastify__toast-container {
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 250px;
    right: 20px;
    width: 360px;

    @media(max-width: 480px) {
      width: 100%;
      right: 0;
      top: 0;
      left: 0;
    }
  }
  .Toastify__toast--default,
  .Toastify__toast--success {
    background: #202028;
    box-shadow: 0 12px 24px 0 rgba(14, 0, 37, 0.32);
    padding: 10px 12px 14px !important;
    border-radius: 0 !important;
    border: none;

    &:after {
      display: none;
    }
    .Toastify__toast-body {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      border: none;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      padding: 0 !important;
      margin: 0;

      &:before {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        background: url('../images/notify-icon-green.svg') no-repeat center;
        background-size: contain;
        flex-shrink: 0;
      }

      & > div {
        width: 100%;
      }
    }
    .notify-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #fff;
      text-shadow: none;
      text-transform: none;
      margin-bottom: 8px;
    }
    .notify-text {
      font-size: 16px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.72);
      border: none;
      opacity: 1;
      text-shadow: none;
      margin: 0;
    }
    .notify-btn {
      display: inline-flex;
      align-items: center;
      height: 32px;
      padding: 0 16px;
      border-radius: 16px;
      font-size: 14px;
      line-height: 140%;
      color: #fff;
      background: #089408;
      border: none;
      box-shadow: none;

      &:hover {
        opacity: 0.8;
      }
    }
    .notify-btn-go-to {
      width: 100%;
      height: 32px;
      background: #2A2A33;
      border: none;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      margin: 12px 0 0;
      display: block;
      transition: background-color 0.2s ease;

      &:hover {
        background: #33333d;
      }
    }
  }
  .Toastify__toast--error {
    @extend .Toastify__toast--default;
    
    .Toastify__toast-body {
      &:before {
        background-image: url('../images/notify-icon-red.svg');
      }
      
      & > div {
        width: 100%;
      }
    }

    .notify-btn-go-to {
      width: 116%;
      margin-left: -45px;
    }
  }
  .Toastify__toast--warning {
    @extend .Toastify__toast--default;
    
    .Toastify__toast-body {
      &:before {
        background-image: url('../images/notify-icon-yellow.svg');
      }
      
      & > div {
        width: 100%;
      }
    }
    
    .notify-btn-go-to {
      width: 116%;
      margin-left: -45px;
    }
  }
  .Toastify__toast--info {
    @extend .Toastify__toast--default;
    
    .Toastify__toast-body {
      &:before {
        background-image: url('../images/notify-icon-blue.svg');
      }
      
      & > div {
        width: 100%;
      }
    }
    
    .notify-btn-go-to {
      width: 116%;
      margin-left: -45px;
    }
  }
}
.Toastify__close-button {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
  cursor: pointer;

  > svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}
.Toastify--animate-icon,
.Toastify__progress-bar {
  visibility: visible;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #202028;
  transition: width 0.3s ease-in-out;
}

.Toastify__progress-bar--info {
  background-color: #3498db;
}

.Toastify__progress-bar--success {
  background-color: #089408;
}

.Toastify__progress-bar--warning {
  background-color: #f1c40f;
}

.Toastify__progress-bar--error {
  background-color: #eb4b4b;
}

.Toastify .Toastify__toast--default,
.Toastify .Toastify__toast--success {
  &.notify-sell-product {
    background: linear-gradient(
      133.83deg,
      #0d0329 1.96%,
      #250055 51.47%,
      #77007b 100%
    );
    box-shadow: 0 0 0 2px rgba(136, 71, 255, 0.16), 0px 20px 28px #13072c,
      0px 28px 64px rgba(211, 44, 230, 0.2);
    border-radius: 20px !important;
    overflow: visible;
    padding: 12px 16px 12px 12px !important;
    border: 0;

    @media(max-width: 480px) {
      width: 100%;
    }
    &:after {
      display: none;
    }
    .Toastify__toast-body {
      border-radius: 24px;
      margin: 0;
      padding: 0 !important;
      border: 0;
      background: none;
      box-shadow: none;
    }
    .notify-product {
      display: flex;

      &-content {
        display: flex;
        flex-direction: column;
        min-width: 190px;
        padding-right: 30px;
        max-width: 210px;
      }
      .top-drop-item {
        margin-right: 16px;

        @media(max-width: 359px) {
          min-width: 108px;
          width: 108px;
        }
        .in-case-cost {
          padding: 2px 8px;
          top: 0;
        }
        &:hover {
          .top-drop-item-hover {
            padding-top: 73px;
          }
        }
      }
      .drop-img {
        width: 100%;
        height: 62px;
        position: relative;
        z-index: 3;
        transition: all 0.3s;

        img {
          width: 92px;
          height: 72px;
          object-fit: contain;
          display: block;
          margin: 0 auto 0 auto;
        }
      }
    }
    .notify-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: gray;
      //text-shadow: 0px 0px 16px #d32ce6;
      //text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6,
      //  1px 1px 0 #d32ce6, 0px 0px 16px #d32ce6;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    .notify-text {
      font-size: 14px;
      line-height: 21px;
      border: 1px solid rgba(211, 44, 230, 0);
      //text-shadow: 0px 0px 32px #4b69ff;
      color: gray;
      margin: 0;
    }
    .notify-price {
      font-size: 14px;
      line-height: 21px;
      color: rgba(213, 185, 255, 0.5);
    }
    .close-button {
      position: absolute;
      z-index: 2;
      top: -24px;
      right: 0;
      color: #fff;
      background: #202028;
      //background: linear-gradient(90deg, #1c1132 0%, #120929 100%);
      //box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.4),
      //  0px 0px 28px rgba(136, 71, 255, 0.4);
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      //color: rgba(136, 71, 255, 0.4);
      transition: all 0.3s;
      font-size: 30px;
      line-height: 30px;
      transition: all 0.3s;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(136, 71, 255, 0.2),
            rgba(136, 71, 255, 0.2)
          ),
          linear-gradient(90deg, #1c1132 0%, #120929 100%);
        box-shadow: inset 0 0 0 4px #8847ff,
          0px 0px 28px rgba(136, 71, 255, 0.4);
        transform: rotate(180deg);
        .icon {
          color: #8847ff;
        }
      }

      &:active {
        background: linear-gradient(90deg, #1c1132 0%, #120929 100%);
        box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.4),
          0px 0px 28px rgba(136, 71, 255, 0.4);
        transform: rotate(180deg);
        .icon {
          color: rgba(136, 71, 255, 0.4);
        }
      }
    }
  }
}

.Toastify__toast {
  margin-bottom: 24px;
  position: relative;
}

.Toastify .Toastify__toast--success.notify-sell-product {
  .notify-quest {
    min-width: 382px;
    padding: 4px 0 4px 4px;

    @media(max-width: 440px) {
      min-width: 100%;
    }

    .notify-product-content {
      max-width: initial;
      padding-right: 0;
    }
  }
}

.notify-quest-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .notify-text {
    font-size: 16px;
    line-height: 16px;
    color: gray;
  }

  .notify-quest-price {
    display: flex;
    margin-left: 16px;
    height: 24px;
    background: linear-gradient(90deg, rgba(0, 87, 0, 0.2) 0%, rgba(8, 148, 8, 0.2) 100%);
    border: 2px solid rgba(6, 197, 12, 0.4);
    border-radius: 12px;
    padding: 2px 8px 2px 5px;

    span {
      margin-right: 4px;
      white-space: nowrap;
    }
  }
}

.notify-btn-go-to {
  height: 36px;
  //background: linear-gradient(90deg, #380B9D 0%, #490475 100%);
  background: #202028;
  border: 2px solid #202028;
  border-radius: 18px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.56);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: linear-gradient(90deg, #490475 0%, #380B9D 100%);
  }
}

.Toastify__toast--success {
  @extend .Toastify__toast--default;
  
  .Toastify__toast-body {
    &:before {
      background-image: url('../images/notify-icon-green.svg');
    }
    
    & > div {
      width: 100%;
    }
  }
  
  .notify-btn-go-to {
    width: 116%;
    margin-left: -45px;
  }
}