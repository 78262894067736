$body-bg: #131318;

body {
  background: $body-bg;
  //background: #13072c;
}

.not-author,
.author {
  //  background: #040817;
}

// .main.author[data-pathname="/"] {
//   background: #040817;
// }