@import "../mixins/mixins";
@import "../components/distribution-item";
@import "../components/header-menu";
@import "../components/login-btns";
@import "../components/header-profile";
@import "../components/mobile-menu";
@import "../components/mobile-menu-bottom";

.main[data-host="justcases.org"],
.main[data-host="hellcases.org"],
.main[data-host="csgohappy.com"],
.main[data-host="key-dropp.net"] {
  .header-left {
    .logo {
      &:hover {
        &:after {
          opacity: 0;
        }

        img {
          opacity: 1;
        }
      }
    }
  }
}

.header {
  // position: relative;
  // background: linear-gradient(180deg, #13072c 42.07%, rgba(19, 7, 44, 0) 66.71%),
  //   linear-gradient(90deg, #3f0449 0%, #250a53 23.08%, #2e0a53 73.34%, #250569 100%), #13072c;
  // border: 1px solid rgba(252, 221, 255, 0.16);
  // z-index: 10;

  // @media (max-width: 1200px) {
  //   background: linear-gradient(180deg, #13072c 42.07%, rgba(19, 7, 44, 0) 66.71%),
  //     linear-gradient(90deg, rgba(52, 4, 73, 0.0001) 0%, #2e0a53 75%, #250569 100%), #13072c;
  //   border: 0;

  //   @include before() {
  //     width: 100%;
  //     height: 2px;
  //     bottom: 0;
  //     left: 0;
  //     background: rgba(252, 221, 255, 0.16);
  //   }
  // }

  // &__wrap {
  //   height: auto;
  //   margin-top: -45px;
  //   position: relative;
  //   @include flex(false, center, false, false);
  //   @include before() {
  //     top: -17px;
  //     width: 100%;
  //     height: 1px;
  //     background: rgba(252, 221, 255, 0.16);

  //     @media (max-width: 1200px) {
  //       display: none;
  //     }
  //   }
  //   @include after() {
  //     top: -16px;
  //     width: 100%;
  //     height: 33px;
  //     background: url(../images/mobile-header-bg.png) no-repeat 0 0;
  //     display: none;
  //   }

  //   @media (max-width: 1200px) {
  //     padding: 4px 0 8px 0;
  //     margin-top: -63px;
  //     z-index: 6;

  //     @include after() {
  //       display: block;
  //     }
  //   }
  //   @media (max-width: 992px) {
  //     @include after() {
  //       display: none;
  //     }
  //   }

  //   & > .header-profile {
  //     @media (max-width: 992px) {
  //       margin-left: 16px;
  //     }
  //   }

  //   & > .header__controls {
  //     @media (max-width: 1200px) {
  //       display: none;
  //     }
  //   }

  //   &.fixed {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     margin-top: 0;
  //     z-index: 99999;
  //     border: 0;
  //     border-bottom: 1px solid rgba(252, 221, 255, 0.16);
  //     border-top: 0;
  //     width: 100%;
  //     height: 110px;

  //     @media (max-width: 1024px) {
  //       height: auto;
  //     }
  //     @include after() {
  //       height: 200px;
  //       width: 100%;
  //       background: linear-gradient(180deg, #13072c 42.07%, rgba(19, 7, 44, 0) 66.71%),
  //         linear-gradient(90deg, #3f0449 0%, #250a53 23.08%, #2e0a53 73.34%, #250569 100%), #13072c;
  //       top: -91px;
  //       z-index: -1;

  //       @media (max-width: 1024px) {
  //         top: auto;
  //         bottom: 0;
  //       }
  //     }

  //     .logo {
  //       @media (max-width: 1024px) {
  //         height: 48px;
  //       }
  //     }
  //   }
  // }

  // .logo {
  //   width: 440px;
  //   height: 92px;
  //   margin-right: auto;
  //   position: relative;
  //   @include flex(false, center, false, false);

  //   @media (max-width: 1600px) {
  //     width: 240px;
  //   }
  //   @media (max-width: 1024px) {
  //     height: 48px;
  //   }

  //   img {
  //     opacity: 1;
  //     transition: all 0.3s;

  //     @media (max-width: 1600px) {
  //       width: 100%;
  //     }
  //     @media (max-width: 1200px) {
  //       opacity: 0;
  //     }
  //   }

  //   @include before() {
  //     width: 440px;
  //     height: 137px;
  //     background: url(../images/logo-blur.svg) no-repeat 0 0;
  //     opacity: 0.64;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     transition: all 0.3s;
  //     @media (max-width: 1600px) {
  //       width: 100%;
  //       background-size: 100% 100%;
  //     }

  //     @media (max-width: 1200px) {
  //       background: url(../images/logo-mobile-blur.svg) no-repeat 50% 50%;
  //       background-size: 100% 100%;
  //     }
  //     @media (max-width: 992px) {
  //       height: 100%;
  //     }
  //   }
  //   @include after() {
  //     width: 100%;
  //     height: 100%;
  //     background: url(../images/logo-mobile.svg) no-repeat 0 0;
  //     background-size: 100% 100%;
  //     opacity: 0;
  //     transition: all 0.3s;

  //     @media (max-width: 1200px) {
  //       opacity: 1;
  //     }
  //   }

  //   &:hover {
  //     &:before {
  //       opacity: 1;
  //     }
  //   }
  // }

  &__controls {
    display: flex;
    height: 48px;
  }

  &__controls-lang {
    height: 100%;
    width: 76px;
    border-radius: 8px 0px 0px 8px;
    display: flex;
    position: relative;
    margin-right: 4px;

    &-current {
      @include flex(false, center, center, false);
      // height: 100%;
      // width: 100%;
      width: 44px;
      height: 44px;
      font-size: 16px;
      line-height: 19px;
      color: rgba(230, 155, 255, 0.4);
      background: #202028;
      cursor: pointer;
      text-transform: uppercase;
      //padding-left: 16px;
      //padding-right: 3px;
      position: relative;
      border-radius: 4px;

      @media (max-width: 1280px) {
        width: 32px;
        height: 32px;
      }

      @include before() {
        border: 2px solid transparent;
        //background: linear-gradient(90deg, #8847ff, #f047ff) border-box;
        -webkit-mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        width: 100%;
        height: 100%;
        border-radius: 8px 0px 0px 8px;
        opacity: 0.08;
        box-sizing: border-box;
        pointer-events: none;
        transition: all 0.5s;
      }

      @include after() {
        background: linear-gradient(-45deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);
        border-radius: 8px 0px 0px 8px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s;
      }

      span {
        margin-right: auto;
      }

      .icon {
        color: rgba(230, 155, 255, 0.4);
        transition: all 0.3s;

        @media (max-width: 1200px) {
          margin-right: 0;
        }
      }

      &:hover {
        color: rgba(230, 155, 255, 0.8);

        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }

        .icon {
          color: rgba(230, 155, 255, 0.8);
        }
      }
    }

    &-dropdown {
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      width: 104px;
      background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%), #13072c;
      box-shadow:
        0px 20px 28px #13072c,
        0px 28px 64px rgba(50, 50, 50, 0.2);
      border-radius: 8px;
      padding: 8px 0;
      z-index: 100;
      max-height: 276px;
      overflow: hidden;
      overflow-y: auto;
      opacity: 0;
      display: none;
      visibility: hidden;
      transition: all 0.3s;
      @include flex(column, center, false, false);
      @include scrollbar(4px, rgba(240, 71, 255, 0.16), rgba(240, 71, 255, 0.16));

      & > :first-child {
        &:before {
          display: none;
        }
      }

      &-item {
        padding: 10px 16px 10px 12px;
        min-width: 36px;
        text-align: center;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.325px;
        text-transform: uppercase;
        color: #ccc;
        cursor: pointer;
        width: 100%;
        position: relative;
        background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.0192) 0%, rgba(57, 15, 99, 4e-6) 100%);
        mix-blend-mode: normal;

        @include flex(false, center, center, false);
        @include before() {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 1px;
          background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.19) 0%, rgba(57, 15, 99, 0) 100%);
          mix-blend-mode: normal;
        }

        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 14px;
        }

        & > span {
          width: 22px;
          text-align: left;
        }

        &:hover {
          color: #ffdf77;
          background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.192) 0%, rgba(57, 15, 99, 4e-5) 100%);
        }

        &.active {
          background: radial-gradient(50% 3200% at 50% 50%, rgba(111, 0, 123, 0.4) 0%, rgba(57, 15, 99, 4e-5) 100%);
          color: #d32ce6;
        }
      }
    }

    &.active {
      .header__controls-lang-current {
        color: rgba(230, 155, 255, 0.8);

        &:before {
          opacity: 0;
        }

        &:after {
          background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);
          opacity: 1;
        }
      }

      .icon {
        transform: rotate(180deg);
        color: rgba(211, 44, 230, 0.4);
      }

      .header__controls-lang-dropdown {
        display: flex;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  // &__controls-feedback,
  // &__controls-sound {
  //   width: 48px;
  //   height: 100%;
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   cursor: pointer;
  //   border-radius: 0px 8px 8px 0px;

  //   @include before() {
  //     border: 2px solid transparent;
  //     background: linear-gradient(90deg, #8847ff, #f047ff) border-box;
  //     -webkit-mask:
  //       linear-gradient(#fff 0 0) padding-box,
  //       linear-gradient(#fff 0 0);
  //     -webkit-mask-composite: xor;
  //     mask-composite: exclude;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 0px 8px 8px 0px;
  //     opacity: 0.08;
  //     box-sizing: border-box;
  //     pointer-events: none;
  //     transition: all 0.5s;
  //   }

  //   @include after() {
  //     width: 100%;
  //     height: 100%;
  //     background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);
  //     border-radius: 0px 8px 8px 0px;
  //     opacity: 0;
  //     transition: all 0.5s;
  //   }

  //   .icon {
  //     color: rgba(230, 155, 255, 0.4);
  //   }

  //   &:hover {
  //     &:after {
  //       opacity: 1;
  //     }

  //     &:before {
  //       opacity: 0;
  //     }

  //     .icon {
  //       color: rgba(230, 155, 255, 0.8);
  //     }
  //   }

  //   &.active {
  //     &:after {
  //       opacity: 1;
  //     }

  //     &:before {
  //       opacity: 0;
  //     }

  //     .icon {
  //       color: rgba(211, 44, 230, 0.4);

  //       &:before {
  //         content: "volume_off";
  //       }
  //     }
  //   }
  // }

  // &__controls-feedback {
  //   margin-right: 12px;
  //   border-radius: 8px;

  //   &:before,
  //   &:after {
  //     border-radius: 8px;
  //   }
  //   .icon {
  //     color: #ffc600;
  //   }

  //   &:hover {
  //     .icon {
  //       color: #ffdf77;
  //     }
  //   }
  // }

  &-search {
    width: 283px;
    height: 48px;
    position: relative;

    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 992px) {
      display: none;
    }

    &__wrap {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
    }

    &-drop-wrap {
      position: absolute;
      z-index: 6;
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      width: 100%;
      background: #363744;
      // box-shadow:
      //   0px 20px 28px #13072c,
      //   0px 28px 64px rgba(50, 50, 50, 0.2);
      border-radius: 8px;
      padding: 8px 0;
      z-index: 100;
      max-height: 276px;
      overflow: hidden;
      overflow-y: auto;
      @include scrollbar(4px, #202028, #363744);
    }

    &-drop-cat {
      .title {
        color: #FFB800;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        padding: 0 22px 0 16px;
      }

      &-item {
        //background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.192) 0%, rgba(57, 15, 99, 0.00004) 100%);
        padding: 10px 16px 10px 12px;
        width: 100%;
        display: block;
        font-size: 16px;
        line-height: 100%;
        color: #ccc;
        text-decoration: none;

        &:hover {
          color: #ffdf77;
          background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.192) 0%, rgba(57, 15, 99, 0.00004) 100%);
        }
      }
    }

    input {
      width: 100%;
      height: 100%;
      background: none;
      border: 0;
      padding: 0 56px 0 16px;
      color: #d32ce6;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;

      @include input-placeholder() {
        color: rgba(230, 155, 255, 0.4);
        font-weight: normal;
      }

      &:focus {
        outline: none;
      }
    }

    @include before() {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 2px solid transparent;
      background: linear-gradient(90deg, rgba(136, 71, 255, 0.08), rgba(240, 71, 255, 0.08)) border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      z-index: 2;
      pointer-events: none;
      box-sizing: border-box;
      transition: all 0.3s;
    }
    @include after() {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(270deg, rgba(240, 71, 255, 0.16) 0%, rgba(136, 71, 255, 0.16) 100%);
      z-index: 2;
      pointer-events: none;
      opacity: 0;
      transition: all 0.3s;
    }

    &__btn {
      position: absolute;
      top: 50%;
      right: 8px;
      width: 32px;
      height: 32px;
      transform: translateY(-50%);
      transition: all 0.3s;
      text-decoration: none;
      color: rgba(230, 155, 255, 0.4);
      @include flex(false, center, center, false);

      &:hover {
        color: rgba(230, 155, 255, 0.8);
      }
    }

    &:hover {
      .header-search__btn {
        color: rgba(230, 155, 255, 0.8);
        pointer-events: none;

        &.active {
          .icon {
            color: rgba(230, 155, 255, 0.4);
          }
        }
      }

      .header-search__wrap {
        border-bottom: 2px solid #6d007a;
      }

      input {
        @include input-placeholder() {
          color: rgba(230, 155, 255, 0.8);
        }
      }

      &:after {
        opacity: 1;
        z-index: 3;
      }

      &:before {
        opacity: 0;
      }
    }
  }
}

// Кнопка скрола страницы наверх
.scroll-page {
  border-radius: 50%;
  position: fixed;
  right: 80px;
  bottom: 80px;
  width: 80px;
  height: 80px;
  z-index: 100;
  background: url(../images/icon-scroll-page.svg) no-repeat 0 0;
  background-size: 100%;
  transition: all 0.5s;
  opacity: 0;

  @media (max-width: 1200px) {
    right: 10px;
    bottom: 10px;
  }
  @media (max-width: 992px) {
    bottom: 58px;
  }
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }

  &:hover {
    background: url(../images/icon-scroll-page-hover.svg) no-repeat 0 0;
    background-size: 100%;
  }

  &:active {
    background: url(../images/icon-scroll-page-active.svg) no-repeat 0 0;
    background-size: 100%;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .tooltip-block {
    margin-top: -3px;
    background: linear-gradient(90deg, rgba(50, 60, 153, 0.32) 0%, rgba(21, 29, 107, 0.32) 100%);
  }

  &:hover {
    .tooltip-block {
      opacity: 1;
    }
  }
}

.main[data-pathname="/"] {
  &[data-host="cscase.com"]:not(.author):not(.distributions-active) {
    .wrap {
      margin-top: 0px;
    }
  }
}

.header-profile-user-mobile {
  background: rgb(63, 0, 97);
  background: -moz-radial-gradient(center, ellipse cover, rgba(63, 0, 97, 1) 0%, rgba(49, 0, 77, 1) 100%);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(63, 0, 97, 1) 0%, rgba(49, 0, 77, 1) 100%);
  background: radial-gradient(ellipse at center, rgba(63, 0, 97, 1) 0%, rgba(49, 0, 77, 1) 100%);
  padding: 4px;
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
  display: none;
  z-index: 6;

  @media (max-width: 1200px) {
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.page-profile-landing {
  &-user {
    .header {
      background: rgba(0, 0, 9, 0.2);
    }
  }

  .header {
    position: relative;
    z-index: 4;

    @media (max-width: 1280px) {
      background: linear-gradient(90deg, rgba(52, 4, 73, 0.0001) 0%, #2e0a53 75%, #250569 100%), #13072c;
      border-bottom: 2px solid rgba(252, 221, 255, 0.16);
    }

    &:before {
      @extend %before-after;
      height: 1px;
      background: rgba(252, 221, 255, 0.12);
      top: 100%;
      width: 100%;
      display: block;

      @media (max-width: 1280px) {
        display: none;
      }
    }

    &:after {
      @extend %before-after;
      height: 1px;
      background: rgba(2, 2, 11, 0.6);
      top: calc(100% + 2px);
      width: 100%;
      display: block;

      @media (max-width: 1280px) {
        display: none;
      }
    }

    &-center {
      padding-right: 0;
      z-index: 3;

      @media (max-width: 1680px) {
        justify-content: center;
      }
      @media (max-width: 1200px) {
        display: none;
      }
    }

    &-right {
      background: url(../images/bg-header-right-2.svg) no-repeat 0 0;
      z-index: 3;

      @media (max-width: 1280px) {
        display: flex;
        background: none;
      }
      @media (max-width: 1200px) {
        display: none;
      }

      .profile {
        padding-top: 26px;

        @media (max-width: 1280px) {
          display: none;
        }

        img {
          border: 0;
        }

        &-top {
          padding-left: 45px;
        }
      }

      .profile-rank {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        color: #d32ce6;

        .icon {
          background: linear-gradient(300deg, #8214a8, #ff6ce3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 18px;
          line-height: 18px;
          margin-left: -3px;
        }
      }

      .logout {
        background: rgba(230, 155, 255, 0.12);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: none;
        margin-left: auto;

        .icon {
          font-size: 18px;
          line-height: 18px;
          opacity: 0.4;
        }

        &:before,
        &:after {
          display: none;
        }
      }
    }

    &-wrap {
      margin-top: 0;
      margin-bottom: 0;

      &:before,
      &:after {
        @extend %before-after;
        background: linear-gradient(90deg, #1f074f 0%, rgba(52, 4, 73, 0.0001) 100%);
        width: 50%;
        left: 0;
        height: 100%;
        z-index: 2;

        @media (max-width: 1280px) {
          display: none !important;
        }
      }

      &:after {
        background: linear-gradient(90deg, rgba(52, 4, 73, 0.0001) 0%, #12154e 75%, #071a4f 100%);
        left: auto;
        right: 0;
      }
    }

    .authorization {
      overflow: hidden;

      @media (max-width: 1280px) {
        display: none;
      }
    }

    .landing-mobile {
      display: none;

      @media (max-width: 1280px) {
        @include flex(false, center, false, false);
      }
      @media (max-width: 1280px) {
        margin-left: auto;
      }

      .header-profile {
        margin-left: 0;

        &__cost {
          padding-top: 0;
          background: linear-gradient(270deg, rgba(211, 44, 230, 0.12) 0%, rgba(136, 71, 255, 0.12) 100%);

          @media (max-width: 359px) {
            padding-left: 10px;
          }
        }

        &__cost-val {
          font-weight: 500;
          color: #fff;
          text-shadow: none;
          font-size: 16px;
          white-space: nowrap;
          @include flex(false, center, false, false);

          @media (max-width: 359px) {
            padding-right: 0;
          }

          svg {
            opacity: 0.5;
            margin-left: 4px;
          }
        }

        &__cost-game-val {
          white-space: nowrap;
        }

        &-user__header {
          margin-right: 8px;
        }

        &__btn {
          height: 100%;
          min-width: 48px;
          position: relative;
          @include flex(false, center, center, false);
          @include before() {
            background: radial-gradient(50% 50% at 50% 50%, rgba(117, 55, 0, 0.48) 0%, rgba(57, 15, 99, 0.0001) 100%);
            mix-blend-mode: normal;
            opacity: 0.6;
            width: 64px;
            height: 64px;
            left: calc(50% - 64px / 2);
            top: calc(50% - 64px / 2);
          }

          .icon {
            color: #ffc600;
            position: relative;
          }
        }
      }

      .header-profile-user {
        min-width: auto;

        @media (max-width: 1280px) {
          display: flex;
        }
        @media (max-width: 420px) {
          margin-right: 0;
        }

        &__header {
          margin-right: 0;

          &:before {
            display: none;
          }
        }
      }
    }

    .logo {
      img {
        @media (max-width: 1200px) {
          opacity: 1;
        }
      }
    }
  }

  .header-wrap {
    @media (max-width: 1200px) {
      border-top: 0;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .wrap {
    margin-top: 0;

    &:after {
      display: none;
    }
  }

  .open-cases {
    margin-top: 0;
    padding-top: 110px;
    background: none;
    margin-bottom: -210px;

    h1 {
      margin-bottom: 15px;
      color: #ffc600;
      text-shadow:
        0px 0px 80px #ffc600,
        0px 0px 24px rgba(255, 198, 0, 0.64);
    }

    h3 {
      margin-bottom: 82px;
      font-weight: 700;
    }
  }

  .not-author {
    .open-cases {
      &:before,
      &:after {
        top: 0;
        pointer-events: none;
      }

      h3 {
        margin-bottom: 82px;
        font-weight: 700;
      }
    }
  }
}

.header {
  &.header-profile-landing {
    max-height: 277px;
    border: 0;

    &:before {
      @media (max-width: 1200px) {
        display: none !important;
      }
    }

    .header-wrap {
      &:before,
      &:after {
        @extend %before-after;
        background: linear-gradient(90deg, #1f074f 0%, rgba(52, 4, 73, 0.0001) 100%);
        width: 50%;
        left: 0;
        height: 100%;
        z-index: 2;
      }

      &:after {
        background: linear-gradient(90deg, rgba(52, 4, 73, 0.0001) 0%, #12154e 75%, #071a4f 100%);
        left: auto;
        right: 0;
      }
    }

    .header-left {
      @media (max-width: 1280px) {
        background: none;
        width: auto;
        min-width: auto;
      }
      @media (max-width: 1200px) {
        height: auto;
        align-items: center;
        display: flex;
      }
      @include before() {
        background: url(../images/bg-warrior-1.png) no-repeat 50% 100%;
        width: 380px;
        height: 178px;
        left: 130px;
        pointer-events: none;
        z-index: 5;

        @media (max-width: 1440px) {
          display: none;
        }
      }
      @include after() {
        background: url(../images/bg-gilza-1.svg) no-repeat 50% 100%;
        width: 145.5px;
        height: 135.2px;
        left: 353px;
        top: 40px;
        pointer-events: none;

        @media (max-width: 1366px) {
          display: none;
        }
      }
    }

    .header-right {
      min-width: 488px;
      width: 488px;

      @media (max-width: 1280px) {
        min-width: auto;
        width: auto;
      }
    }

    .logo {
      width: 100%;
      height: 156px;
      object-fit: contain;
      position: relative;
      display: block;
      margin-top: -10px;

      @media (max-width: 1200px) {
        width: 88px;
        height: 60px;
        margin-top: 0;
      }
      @media (max-width: 380px) {
        width: 68px;
      }

      img {
        margin-top: 10px;
        max-width: 210px;
        height: auto;

        @media (max-width: 1280px) {
          background: url(../images/logo-mobile.svg) no-repeat 50% 50%;
          object-position: -99999px 99999px;
        }
        @media (max-width: 1200px) {
          width: 88px;
          height: 60px;
          background-size: 100% 100%;
          margin-top: 0;
        }
        @media (max-width: 359px) {
          width: 68px;
        }
      }

      &:before {
        pointer-events: none;
        width: 165px;
        height: 165px;
        background: url(../images/bg-gilza-1.png) no-repeat 0 0;
        top: -7px;
        left: 162px;
        z-index: 4;
        opacity: 1;
        transform: none;
        margin-top: 0;

        @media (max-width: 1280px) {
          display: none;
        }
      }

      &:after {
        width: 224px;
        height: 190px;
        position: absolute;
        top: -1px;
        left: -7px;
        opacity: 0;
        background: url(../images/logo-old-hover.svg) no-repeat 0 0;

        @media (max-width: 1280px) {
          background: url(../images/logo-mobile-blur.svg) no-repeat 50% 50%;
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &-wrap {
    display: flex;
    position: relative;
    border-top: 1px solid rgba(252, 221, 255, 0.16);
    background: rgba(0, 0, 9, 0.2);
    border-bottom: 2px solid rgba(252, 221, 255, 0.16);

    @media (max-width: 1200px) {
      height: 80px;
    }
  }

  &-left {
    min-width: 494px;
    width: 494px;
    height: 158px;
    background: url(../images/bg-header-left.svg) no-repeat 0 0;
    position: relative;
    z-index: 2;

    @media (max-width: 1440px) {
      min-width: 394px;
      width: 394px;
      background-position: 100% 0;
    }
    @media (max-width: 1366px) {
      min-width: 324px;
      width: 324px;
    }
    @media (max-width: 1200px) {
      height: auto;
    }

    .logo {
      width: 400px;
      height: 156px;
      object-fit: contain;
      position: relative;
      display: block;
      margin-top: -10px;

      @media (max-width: 1200px) {
        width: 88px;
        height: 60px;
      }

      &:before {
        margin-top: 18px;
        width: 400px;
        margin-left: 0;
        background-size: 100%;
      }

      img {
        width: 100%;
      }
    }
  }

  &-center {
    padding-right: 0;
    z-index: 3;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  &-right {
    background: url(../images/bg-header-right-2.svg) no-repeat 0 0;
    z-index: 3;
    position: relative;

    .header__controls {
      position: absolute;
      top: 5px;
      left: -99px;

      &-lang {
        position: absolute;
        top: 0;
        left: 0;
        width: 96px;
        height: 48px;
        cursor: pointer;
        border-radius: 8px;
        z-index: 7;
      }
    }

    .profile {
      padding: 26px 0 0 117px;
      position: relative;
      z-index: 5;
      overflow: hidden;
      height: 100%;

      img {
        border: 8px solid #31004d;
      }

      &-top {
        margin-bottom: 10px;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding-left: 45px;
        padding-right: 25px;

        img {
          margin-right: 20px;
          width: 34px;
          height: 34px;
          box-shadow: 0 0 0 8px #36075d;
          border-radius: 50%;
        }

        a {
          display: flex;
          align-items: center;
          white-space: nowrap;
          font-size: 16px;
          line-height: 1.5;
          color: #bfbfbf;
          text-decoration: none;

          &.name {
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: 5px;
            }
          }
        }
      }

      &-bot {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 10px;
        padding-left: 45px;
      }
    }

    .profile-rank {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 16px;
      color: #d32ce6;

      .icon {
        background: linear-gradient(300deg, #8214a8, #ff6ce3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 18px;
        line-height: 18px;
        margin-left: -3px;
      }
    }

    .logout {
      background: rgba(230, 155, 255, 0.12);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: none;

      .icon {
        font-size: 18px;
        line-height: 18px;
        opacity: 0.4;
        color: rgba(230, 155, 255, 0.4);
      }
    }
  }
}
