@import '../mixins/mixins';

.in-case-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;

  @media(max-width: 1330px) {
    margin: 0;
    padding: 0 16px;
  }
  @media(max-width: 359px) {
    padding: 0 6px;
  }

  .in-case-item {
    &:hover {
      z-index: 4;
    }
  }
}

.in-case-item {
  width: calc(16.66% - 12px);
  height: 180px;
  margin: 0 6px 12px 6px;
  border-radius: 12px;
  background: #1D0B43;

  @media(max-width: 1330px) {
    width: calc(20% - 8px);
    margin: 0 4px 8px 4px;
  }
  @media(max-width: 992px) {
    width: calc(25% - 8px);
  }
  @media(max-width: 768px) {
    width: calc(33.3% - 8px);
    height: 156px;
  }
  @media(max-width: 560px) {
    width: calc(50% - 8px);
  }

  .in-case-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%) scale(0.98);
    opacity: 0;
    transition: all 0.5s;

    @media(max-width: 992px) {
      display: none;
    }
    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin: 0 4px;
      position: relative;
      transition: all 0.5s;

      img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        padding: 0;
        border: 0;
      }

      &.btn-case {
        background: linear-gradient(#8847ff 0%, #8847ff 100%);
        border: 2px solid #5e98d9;

        img {
          width: 64px;
          height: 64px;
          object-fit: contain;
        }
        span {
          align-items: center;
          background: #7d36ff;
          background: -moz-linear-gradient(left,#7d36ff 0,#3900a1 100%);
          background: -webkit-linear-gradient(left,#7d36ff,#3900a1);
          background: linear-gradient(90deg,#7d36ff 0,#3900a1);
          border-radius: 4px;
          color: #fff;
          display: flex;
          font-size: 14px;
          height: 20px;
          justify-content: center;
          left: 50%;
          line-height: 24px;
          opacity: 0;
          padding: 0 8px;
          pointer-events: none;
          position: absolute;
          text-align: center;
          top: calc(100% - 2px);
          transform: translate(-50%,-10px);
          transition: all .3s;
          white-space: nowrap;
          z-index: -1;
        }

        &:hover {
          border: 4px solid #5e98d9;

          span {
            opacity: 1;
            transform: translate(-50%,10px);
          }
        }
      }

      &.btn-search {
        background: linear-gradient(90deg, #1c1132 0%, #120929 100%);
        border: 2px solid #323c98;
        color: #4b69ff;
        text-decoration: none;

        .icon {
          font-size: 30px;
        }

        img {
          width: 64px;
          height: 64px;
          object-fit: contain;
        }

        &:hover {
          border: 4px solid #4b69ff;
        }
      }

      &.btn-user {
        background: linear-gradient(90deg, #380b9d 0%, #490475 100%);
        padding: 2px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          position: relative;
          border-radius: 50%;
        }

        &:hover {
          padding: 4px;
        }
      }

      &.btn-contracts,
      &.btn-withdraw,
      &.btn-sell {
        background: linear-gradient(#7d36ff 0%, #3900a1 100%);
        border: 2px solid #8847ff;

        span {
          position: absolute;
          top: calc(100% - 2px);
          left: 50%;
          transform: translate(-50%, -10px);
          height: 20px;
          border-radius: 4px;
          background: rgb(125, 54, 255);
          background: -moz-linear-gradient(
                          left,
                          rgba(125, 54, 255, 1) 0%,
                          rgba(57, 0, 161, 1) 100%
          );
          background: -webkit-linear-gradient(
                          left,
                          rgba(125, 54, 255, 1) 0%,
                          rgba(57, 0, 161, 1) 100%
          );
          background: linear-gradient(
                          to right,
                          rgba(125, 54, 255, 1) 0%,
                          rgba(57, 0, 161, 1) 100%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #fff;
          white-space: nowrap;
          padding: 0 8px;
          opacity: 0;
          transition: all 0.5s;
          z-index: -1;
        }

        &:hover {
          border: 4px solid #8847ff;

          span {
            opacity: 1;
            transform: translate(-50%, 10px);
          }
        }
      }

      &.btn-withdraw {
        background: linear-gradient(#0370ff 0%, #0652ff 100%);
        border: 2px solid #6595ff;

        span {
          background: rgb(3, 112, 255);
          background: -moz-linear-gradient(
                          left,
                          rgba(3, 112, 255, 1) 0%,
                          rgba(6, 82, 255, 1) 100%
          );
          background: -webkit-linear-gradient(
                          left,
                          rgba(3, 112, 255, 1) 0%,
                          rgba(6, 82, 255, 1) 100%
          );
          background: linear-gradient(
                          to right,
                          rgba(3, 112, 255, 1) 0%,
                          rgba(6, 82, 255, 1) 100%
          );
        }

        &:hover {
          border: 4px solid #6595ff;
        }
      }

      &.btn-sell {
        background: linear-gradient(#00b700 0%, #089408 100%);
        border: 2px solid #06c50c;

        img {
          max-width: 32px;
          max-height: 32px;
        }

        span {
          background: rgb(0, 188, 0);
          background: -moz-linear-gradient(
                          left,
                          rgba(0, 188, 0, 1) 0%,
                          rgba(3, 152, 3, 1) 100%
          );
          background: -webkit-linear-gradient(
                          left,
                          rgba(0, 188, 0, 1) 0%,
                          rgba(3, 152, 3, 1) 100%
          );
          background: linear-gradient(
                          to right,
                          rgba(0, 188, 0, 1) 0%,
                          rgba(3, 152, 3, 1) 100%
          );
        }

        &:hover {
          border: 4px solid #06c50c;
        }
      }
    }
  }

  &:hover {
    .in-case-item-rar-table-wrap {
      opacity: 1;
    }
    .img {
      & > img {
        transform: translateY(-5px);
      }
    }

    .in-case-btns {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }

    .in-case-item-rar-table-wrap {
      opacity: 1;
    }
  }

  &-wrap {
    position: relative;
    height: 100%;
  }

  .stattrack {
    height: 22px;
    background: #ffc64b;
    border: 2px solid #fffc00;
    position: absolute;
    top: 100%;
    left: 7px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: #050b16;
    border-radius: 4px;
    margin-top: -14px;
    z-index: 4;
    font-weight: 500;

    .fa {
      margin-right: 3px;
    }
  }

  .img {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    @include after() {
      border-radius: 12px;
      border: 2px solid transparent;
      background: linear-gradient(180deg, rgba(176, 195, 217, 0.2), rgba(176, 195, 217, 0.2)) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    img {
      display: block;
      width: 212px;
      height: auto;
      object-fit: contain;
      position: relative;
      z-index: 3;
      filter: drop-shadow(2px 8px 6px rgba(0, 0, 0, 0.36));
      padding: 10px;
      transition: all .3s;

      @media(max-width: 768px) {
        width: 136px;
      }
    }
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    // background-color: #EB4B4B;
    // -webkit-mask-image: url(../images/case-bg.svg);
    // -webkit-mask-size: cover;
    // -webkit-mask-position: 50%;
    // border-radius: 12px;
    //background-image: url(../images/bg-live-item-white22.svg);
  }

  &[data-rar='4b69ff'] {
    .in-case-item-bg {
      background-color: #4b69ff;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(75, 105, 255, 0.2), rgba(75, 105, 255, 0.2)) border-box;
      }
    }
  }

  &[data-rar='e4ae39'] {
    .in-case-item-bg {
      background-color: #e4ae39;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(228, 174, 57, 0.2), rgba(228, 174, 57, 0.2)) border-box;
      }
    }
  }

  &[data-rar='5e98d9'] {
    .in-case-item-bg {
      background-color: #5e98d9;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(94, 152, 217, 0.2), rgba(94, 152, 217, 0.2)) border-box;
      }
    }
  }

  &[data-rar='b0c3d9'] {
    .in-case-item-bg {
      background-color: #b0c3d9;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(176, 195, 217, 0.2), rgba(176, 195, 217, 0.2)) border-box;
      }
    }
  }

  &[data-rar='d32ce6'] {
    .in-case-item-bg {
      background-color: #d32ce6;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(211, 44, 230, 0.2), rgba(211, 44, 230, 0.2)) border-box;
      }
    }
  }

  &[data-rar='8847ff'] {
    .in-case-item-bg {
      background-color: #8847ff;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(136, 71, 255, 0.2), rgba(136, 71, 255, 0.2)) border-box;
      }
    }
  }

  &[data-rar='eb4b4b'] {
    .in-case-item-bg {
      background-color: #eb4b4b;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(235, 75, 75, 0.2), rgba(235, 75, 75, 0.2)) border-box;
      }
    }
  }

  &[data-rar='ffd600'],
  &[data-rar='ffd700'] {
    .in-case-item-bg {
      background-color: #ffd700;
    }

    .img {
      @include after() {
        background: linear-gradient(180deg, rgba(255, 214, 0, 0.2), rgba(255, 214, 0, 0.2)) border-box;
      }
    }
  }

  .in-case-win-ago {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #736687;
    margin-bottom: 6px;
    padding-left: 10px;
  }

  &.active {
    .img {
      border: 4px solid #fff;
      border-radius: 14px;
      box-shadow: inset 0 0 14px 0 #b0c3d9, 0 0 14px 0 #b0c3d9;
      height: calc(100% + 16px);
      width: calc(100% + 16px);
      top: -8px;
      left: -8px;

      @include before() {
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border: 2px solid #b0c3d9;
        background: none !important;
        left: -5px;
        border-radius: 13px;
      }
      @include after() {
        background: red;
        border: 2px solid transparent;
        border-radius: 8px;
      }
    }

    .in-case-item-stats {
      top: -3px;
      right: -2px;
      
      .btn-sale-cost {
        top: 28px;
        right: 0;
        left: auto;
        margin-right: 4px;

        @media(max-width: 992px) {
          display: none;
        }
      }
      .in-case-cost {
        margin-left: 4px;
      }
    }

    &[data-rar="b0c3d9"] {
      .img {
        box-shadow: inset 0 0 14px 0 #b0c3d9, 0 0 14px 0 #b0c3d9;

        &:before {
          border: 2px solid #b0c3d9;
        }

        &:after {
          background: #b0c3d9;
        }
      }
    }

    &[data-rar="5e98d9"] {
      .img {
        box-shadow: inset 0 0 14px 0 #5e98d9, 0 0 14px 0 #5e98d9;

        &:before {
          border: 2px solid #5e98d9;
        }

        &:after {
          background: #5e98d9;
        }
      }
    }

    &[data-rar="4b69ff"] {
      .img {
        box-shadow: inset 0 0 14px 0 #4b69ff, 0 0 14px 0 #4b69ff;

        &:before {
          border: 2px solid #4b69ff;
        }

        &:after {
          background: #4b69ff;
        }
      }
    }

    &[data-rar="8847ff"] {
      .img {
        box-shadow: inset 0 0 14px 0 #8847ff, 0 0 14px 0 #8847ff;

        &:before {
          border: 2px solid #8847ff;
        }

        &:after {
          background: #8847ff;
        }
      }
    }

    &[data-rar="d32ce6"] {
      .img {
        box-shadow: inset 0 0 14px 0 #d32ce6, 0 0 14px 0 #d32ce6;

        &:before {
          border: 2px solid #d32ce6;
        }

        &:after {
          background: #d32ce6;
        }
      }
    }

    &[data-rar="eb4b4b"] {
      .img {
        box-shadow: inset 0 0 14px 0 #eb4b4b, 0 0 14px 0 #eb4b4b;

        &:before {
          border: 2px solid #eb4b4b;
        }

        &:after {
          background: #eb4b4b;
        }
      }
    }

    &[data-rar="e4ae39"] {
      .img {
        box-shadow: inset 0 0 14px 0 #e4ae39, 0 0 14px 0 #e4ae39;

        &:before {
          border: 2px solid #e4ae39;
        }

        &:after {
          background: #e4ae39;
        }
      }
    }

    &[data-rar="ffd700"],
    &[data-rar="ffc600"],
    &[data-rar="ffc700"], {
      .img {
        box-shadow: inset 0 0 14px 0 #ffd700, 0 0 14px 0 #ffd700;

        &:before {
          border: 2px solid #ffd700;
        }

        &:after {
          background: #ffd700;
        }
      }
    }
  }
}

.in-case-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6px;
  left: 8px;
  font-size: 14px;
  line-height: 14px;
  opacity: 0.8;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: left;
  z-index: 4;

  .in-case-item-name {
    position: relative;
    color: #fff;
  }
}

.in-case-cost {
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  border-radius: 12px;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 20px;
  color: #fff;
  padding: 0 12px;
  z-index: 5;
  display: flex;
  align-items: center;
  font-weight: 700;

  .fa {
    margin-left: 5px;
  }
}

.in-case-item {
  &.status-9,
  &.status-1 {
    .in-case-cost {
      background: linear-gradient(to right, #26003e 0%, #230170 100%);
      box-shadow: inset 0px 0px 0px 4px rgba(40, 4, 161, 0.24);
    }
  }

  &.status-2 {
    .in-case-cost {
      background: linear-gradient(to right, #005700 0%, #089408 100%);
      box-shadow: inset 0px 0px 0px 2px #06c50c;
    }

    .in-case-status-default {
      .in-case-status-default-icon {
        background: linear-gradient(#005700 0%, #089408 100%);
        box-shadow: inset 0px 0px 0px 2px #06c50c;
      }

      .in-case-status-text {
        background: linear-gradient(to right, #00bc00 0%, #039803 100%);
      }
    }

    &:hover {
      .in-case-status-default-icon {
        box-shadow: inset 0px 0px 0px 4px #06c50c;
      }
    }
  }

  &.status-3,
  &.status-7,
  &.status-8 {
    .in-case-cost {
      background: linear-gradient(to right, #002e87 0%, #0652ff 100%);
      box-shadow: inset 0px 0px 0px 2px #6595ff;
    }

    .in-case-status-default {
      .in-case-status-default-icon {
        background: linear-gradient(#002e87 0%, #0652ff 100%);
        box-shadow: inset 0px 0px 0px 2px #6595ff;
      }

      .in-case-status-text {
        background: linear-gradient(to right, #0370ff 0%, #0652ff 100%);
      }
    }

    &:hover {
      .in-case-status-default-icon {
        box-shadow: inset 0px 0px 0px 4px #6595ff;
      }
    }
  }

  &.status-4 {
    .in-case-cost {
      background: linear-gradient(to right, #2e0675 0%, #5b17d8 100%);
      box-shadow: inset 0px 0px 0px 2px #8847ff;
    }

    .in-case-status-default {
      .in-case-status-default-icon {
        background: linear-gradient(#2e0675 0%, #5b17d8 100%);
        box-shadow: inset 0px 0px 0px 2px #8847ff;
      }

      .in-case-status-text {
        background: linear-gradient(to right, #7d36ff 0%, #3900a1 100%);
      }
    }

    &:hover {
      .in-case-status-default-icon {
        box-shadow: inset 0px 0px 0px 4px #8847ff;
      }
    }
  }

  &.status-5 {
    .in-case-cost {
      background: linear-gradient(to right, #4f0857 0%, #b111c4 100%);
      box-shadow: inset 0px 0px 0px 2px #d32ce6;
    }

    .in-case-status-default {
      .in-case-status-default-icon {
        background: linear-gradient(#4f0857 0%, #b111c4 100%);
        box-shadow: inset 0px 0px 0px 2px #d32ce6;
      }

      .in-case-status-text {
        background: linear-gradient(to right, #4f0857 0%, #b111c4 100%);
      }
    }

    &:hover {
      .in-case-status-default-icon {
        box-shadow: inset 0px 0px 0px 4px #d32ce6;
      }
    }
  }

  &.status-6 {
    .in-case-cost {
      background: linear-gradient(#2e0675 0%, #5b17d8 100%);
      border: 2px solid #8847ff;
    }

    .in-case-status-default {
      .in-case-status-default-icon {
        background: linear-gradient(#2e0675 0%, #5b17d8 100%);
        box-shadow: inset 0px 0px 0px 2px #8847ff;

        img,
        svg {
          width: 16px;
          height: 16px;
        }
      }

      .in-case-status-text {
        background: linear-gradient(#2e0675 0%, #5b17d8 100%);
      }
    }

    &:hover {
      .in-case-status-default-icon {
        box-shadow: inset 0px 0px 0px 4px #8847ff;
      }
    }
  }

  .in-case-status-default {
    &.in-case-status-event {
      .in-case-status-default-icon {
        background: linear-gradient(90deg, #120014 0%, #4A0053 100%);
        box-shadow: inset 0px 0px 0px 2px #8847FF;

        img,
        svg {
          width: 100%;
          height: 100%;
        }
      }

      .in-case-status-text {
        background: linear-gradient(90deg, #380B9D 0%, #490475 100%);
      }
    }

    &.in-case-status-give {
      .in-case-status-default-icon {
        background: linear-gradient(270deg, #13072C -25%, #220462 125%);
        box-shadow: inset 0px 0px 0px 2px #2E008D;


        img,
        svg {
          width: 16px;
          height: 16px;
        }
      }

      .in-case-status-text {
        background: linear-gradient(90deg, #380B9D 0%, #490475 100%);
      }

      .icon {
        color: #FF2C3B;
        text-shadow: 0px 0px 12px #ff2f2f;
        background: #FEF2C8;
        border-radius: 50%;
        font-size: 25px;
        margin: 0 -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    }
  }

  &:hover {
    .in-case-status-default {
      &.in-case-status-event {
        .in-case-status-default-icon {
          box-shadow: 0px 0px 0px 4px #8847ff;
        }
      }

      &.in-case-status-give {
        .in-case-status-default-icon {
          box-shadow: 0px 0px 0px 4px #2E008D;
        }
      }
    }
  }
}

.in-case {
  margin: 0 auto 83px auto;
  margin-top: 40px;
  position: relative;
  z-index: 3;
  padding-top: 37px;
  max-width: 1240px;
  user-select: none;

  @media(max-width: 1330px) {
    max-width: 100%;
  }

  &:before {
    @extend %before-after;
    // width: 640px;
    // height: 4px;
    background: -moz-linear-gradient(
                    left,
                    rgba(41, 137, 216, 0) 0%,
                    #4b69ff 50%,
                    rgba(41, 137, 216, 0) 100%
    );
    background: -webkit-linear-gradient(
                    left,
                    rgba(41, 137, 216, 0) 0%,
                    #4b69ff 50%,
                    rgba(41, 137, 216, 0) 100%
    );
    background: linear-gradient(
                    to right,
                    rgba(41, 137, 216, 0) 0%,
                    #4b69ff 50%,
                    rgba(41, 137, 216, 0) 100%
    );
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-rar='b0c3d9'] {
    &:before {
      background: linear-gradient(
                      to right,
                      rgba(41, 137, 216, 0) 0%,
                      #b0c3d9 50%,
                      rgba(41, 137, 216, 0) 100%
      );
    }

    .in-case-title {
      color: #b0c3d9;
      text-shadow: 0px 0px 36px #b0c3d9;
    }
  }

  &-item {
    position: relative;
  }
}

.in-case-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #4b69ff;
  text-shadow: 0px 0px 36px #4b69ff;
  text-transform: uppercase;
  margin-bottom: 32px;

  @media(max-width: 1330px) {
    padding: 0 20px;
  }
  @media(max-width: 359px) {
    padding: 0 10px;
  }
}

.in-case-title.title-fair-play {
  display: flex;
  align-items: center;
  position: relative;

  @media(max-width: 992px) {
    flex-direction: column;
    gap: 30px;
  }

  & > a {
    z-index: 1;

    @media(max-width: 992px) {
      order: 3;
      width: 100%;

      button {
        width: 100%;
        text-align: center;
      }
    }
  }

  .name {
    margin: 0 0 0 150px;
    width: 100%;

    @media(max-width: 992px) {
      margin: 0;
    }
  }

  & > .b-violet,
  .seed-form {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .seed-form {
    @media(max-width: 1330px) {
      left: 20px;
    }
    @media(max-width: 992px) {
      position: relative;
      left: 0;
      order: 2;
      top: 0;
      transform: translateY(0);
      width: 100%;

      input {
        width: 100%;
      }
    }

    input {
      &:focus {
        outline: none;
      }
    }
  }

  .b-violet {
    white-space: nowrap;
    text-transform: uppercase;
  }
}

.in-case-status-default {
  position: absolute;
  top: 0;
  left: 0px;
  cursor: pointer;
  z-index: 5;

  .in-case-status-default-icon {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: linear-gradient(90deg, #26003e 0%, #230170 100%);
    box-shadow: 0px 0px 0px 4px rgba(40, 4, 161, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s;
  }

  .in-case-status-text {
    position: absolute;
    top: 50%;
    left: 100%;
    white-space: nowrap;
    transform: translateY(-50%);
    margin-top: 12px;
    margin-left: -3px;
    background: linear-gradient(#26003e 0%, #230170 100%);
    padding: 0 8px;
    font-size: 14px;
    color: $white;
    display: flex;
    align-items: center;
    height: 20px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: -1;
  }

  &:hover {
    .in-case-status-text {
      opacity: 1;
      visibility: visible;
    }
  }
}

.in-case-item-rar-table-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(19, 7, 44, 0.8);
  border: 2px solid rgba(247, 219, 255, 0.12);
  border-radius: 8px;
  z-index: 4;
  padding: 28px 4px 8px 8px;
  opacity: 0;
  transition: opacity 0.3s;

  .in-case-item-rar-table {
    overflow: auto;
    max-height: calc(100% - 28px);
    position: relative;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #363744;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #5A00FF;
      border-radius: 4px;
    }

    &-row,
    &-head {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
      text-shadow: 0px 2px 3px #13072C;
      margin-bottom: 2px;
      padding-right: 4px;

      @media(max-width: 359px) {
        font-size: 10px;
      }

      span {
        margin-right: 4px;
      }

      & > :first-child {
        width: 44px;
        text-align: left;
      }

      & > :nth-child(2) {
        text-align: right;
        color: #06C50C;
        text-shadow: 0px 2px 3px #13072C;
        margin-right: auto;
      }

      & > :nth-child(3) {
        text-align: right;
        margin-right: 0;
      }
    }

    &-head {
      margin-bottom: 4px;

      & > :first-child {
        span {
          text-align: left;
        }
      }

      span {
        color: #FFFFFF;
        opacity: 0.5;
      }
    }
  }
}

.in-case-item-stats {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  z-index: 5;

  .in-case-cost,
  .drop-chance {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .drop-chance {
    position: relative;
    left: 0;
    bottom: 0;
    font-size: 12px;
    line-height: 16px;
    color: rgba(213, 185, 255, 0.8);
    background: rgba(49, 30, 68, 0.64);
    box-shadow: inset 0 0 0 2px rgba(100, 74, 103, 0.16);
    border-radius: 12px;
    margin-left: 16px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: 0;
  }
}

.in-case-btns-mob {
  position: absolute;
  top: 24px;
  right: 0;
  height: calc(100% - 24px);
  width: 108px;
  padding: 4px;
  @include flex(column, false, false, nowrap);
  gap: 4px;
  z-index: 5;
  display: none;

  @media(max-width: 992px) {
    display: flex;
  }

  a,
  button {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    transition: all .3s;
    text-decoration: none;
    border-radius: 8px;
    @include flex(column, center, center, nowrap);
    flex-grow: 1;
    height: 33%;
  }

  &-case {
    background: rgba(230, 155, 255, 0.04);
    backdrop-filter: blur(8px);
    font-size: 14px;
    line-height: 16px;
    color: rgba(213, 185, 255, 0.8) !important;
    transition: all .3s;

    &:hover {
      background: rgba(230, 155, 255, 0.08);
    }
  }

  &-withdraw {
    background: linear-gradient(90deg, #0370FF 0%, #0652FF 100%);
    box-shadow: inset 0 0 0 2px #6595FF;

    &:hover {
      box-shadow: inset 0 0 0 4px #6595FF;
    }
  }

  &-sell {
    background: linear-gradient(90deg, #00BC00 0%, #039803 100%);
    box-shadow: inset 0 0 0 2px #06C50C;

    &:hover {
      box-shadow: inset 0 0 0 4px #06C50C;
    }
  }

  &.approve {
    .in-case-btns-mob-case,
    .in-case-btns-mob-withdraw {
      display: none;
    }
  }
}