@import '../mixins/mixins';

button {
  position: relative;
  padding: 0;

  .loader-item {
    padding: 0;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #3957aa;
  }
}

.btn {
  &.btn-violet {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;
    color: #d32ce6;
    text-shadow: 0px 0px 6px rgba(211, 44, 230, 0);
    padding: 0 34px;
    height: 64px;
    border: 4px solid #fff;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px #d32ce6, inset 0px 0px 10px #d32ce6,
      inset 0px 0px 42px #d32ce6;
    transition: all 0.5s;

    &:hover {
      text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6,
        1px 1px 0 #d32ce6, 0px 0px 8px #d32ce6;
      color: #fff;
    }

    &.disabled {
      font-size: 18px;
      letter-spacing: 0.05em;
      line-height: 24px;
      color: #7b7b7b;
      text-shadow: 0px 0px 6px rgba(211, 44, 230, 0);
      padding: 0 34px;
      height: 64px;
      border-radius: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      pointer-events: none;
      border: 4px solid #fff;
      box-shadow: inset 0px 0px 8px #d32ce6, 0px 0px 8px #d32ce6;
      background: rgba(211, 44, 230, 0.2);
    }
  }

  &.btn-violet-2 {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;
    color: #8847ff;
    text-shadow: 0px 0px 6px rgba(211, 44, 230, 0);
    padding: 0 34px;
    height: 64px;
    border: 4px solid #fff;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 32px #d32ce6, inset 0px 0px 32px #d32ce6,
      inset 0px 0px 32px #d32ce6, inset 0px 0px 8px #d32ce6;
    transition: all 0.5s;
    background: rgba(254, 242, 200, 0);

    &:hover {
      text-shadow: -1px -1px 0 #8847ff, 1px -1px 0 #8847ff, -1px 1px 0 #8847ff,
        1px 1px 0 #8847ff, 0px 0px 8px #8847ff;
      color: #fff;
    }
  }
}

.in-case-sort {
  height: 36px;
  border-radius: 18px;
  border: 3px solid #3b0274;
  padding: 0 10px 0 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #4c259a;
  text-shadow: 0px 0px 36px #b328c9;
  transition: all 0.5s;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;

  svg {
    width: 32px;
    height: 32px;
    margin-right: -10px;

    path {
      transition: all 0.5s;
    }
  }

  &:hover {
    border: 3px solid #8847ff;
    color: #8847ff;

    svg {
      path {
        fill: #8847ff;
      }
    }
  }

  &.active {
    svg {
      transform: scale(-1, -1);
    }
  }
}

.btn-sale-cost {
  background-image: linear-gradient(to right, #330351, #1d0457);
  border: none;
  box-shadow: none;
  min-width: 41px;
  height: 20px;
  border-radius: 10px;
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #eb4b4b;
  text-align: center;
  border-radius: 20px;
  padding: 1px 8px;
  position: absolute;
  right: 0;
  top: -13px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    background-image: linear-gradient(to right, #330351, #1d0457);
    display: block;
    width: 100%;
    z-index: 2;
    margin-top: 1px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    box-shadow: 0 0 3px 0 #ff6262;
    background-color: #ffc2c3;
    display: block;
    width: calc(100% - 8px);
    z-index: 2;
  }

  .fas,
  .fa {
    top: 1px !important;
    font-size: 12px;
    font-weight: 600;
    color: #eb4b4b;
    margin-left: 2px !important;
    padding: 0;
  }
}

.btn-free-case-count {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b1674;
}

.btn-sale-cost-wrap {
  position: absolute;
  right: 0;
  top: -13px;
  display: flex;
  flex-direction: row-reverse;

  .btn-sale-cost {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    margin-left: 2px;
    border-radius: 2px 14px 14px 2px;

    &.percent {
      right: auto;
      border-radius: 14px 2px 2px 14px;
      color: #ffc600;

      &::after,
      &::before {
        display: none;
      }
    }
  }
}

.page-profile {
  .in-case-item {
    .btn-sale-cost {
      left: 30px;
      right: auto;
      z-index: 3;
      top: -6px;
      opacity: 0;
      transition: all 0.5s;

      &.percent {
        &:before,
        &:after {
          display: none;
        }
      }

      .fas,
      .fa {
        top: 1px !important;
        font-size: 12px;
        font-weight: 600;
        color: #eb4b4b;
        margin-left: 2px !important;
        padding: 0;
      }
    }

    &.active {
      .btn-sale-cost {
        opacity: 1;
      }
    }
  }
}

.authorization {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 115px;

  .steam {
    text-decoration: none;
    display: flex;
    align-items: center;

    .icon {
      width: 113px;
      height: 104px;
      position: relative;
      margin-right: 25px;
      transition: all 0.5s;

      &:before {
        @extend %before-after;
        background: url(../images/icon-auth-steam.svg) no-repeat 0 0;
        width: 251px;
        height: 226px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }

      &:after {
        @extend %before-after;
        background: url(../images/icon-auth-steam-hover.svg) no-repeat 0 0;
        width: 251px;
        height: 226px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        opacity: 0;
        transition: all 0.5s;
      }
    }

    .text {
      display: flex;
      flex-direction: column;

      .small {
        opacity: 0.48;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #ffc600;
        transition: all 0.5s;
      }

      .big {
        text-shadow: 0 0 19px #ffc600;
        font-size: 32px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0.8px;
        color: #ffc600;
        text-transform: uppercase;
        transition: all 0.5s;
      }
    }

    &:hover {
      .icon {
        transform: translateX(-4px);

        &:after {
          opacity: 1;
        }
      }

      .small {
        opacity: 1;
      }

      .big {
        text-shadow: -1px -1px 0 #ffc600, 1px -1px 0 #ffc600, -1px 1px 0 #ffc600,
          1px 1px 0 #ffc600, 0 0 19px #ffc600;
        color: $white;
      }
    }
  }

  &-social {
    display: flex;
    flex-direction: column;
    padding-right: 31px;

    .vk {
      width: 61px;
      height: 54px;
      position: relative;
      margin-bottom: 10px;

      &:before {
        @extend %before-after;
        background: url(../images/icon-auth-vk.svg) no-repeat 0 0;
        width: 128px;
        height: 119px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        transition: all 0.5s;
      }

      &:after {
        @extend %before-after;
        background: url(../images/icon-auth-vk-hover.svg) no-repeat 0 0;
        width: 140px;
        height: 131px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        opacity: 0;
        transition: all 0.5s;
        margin-left: 1px;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    .google {
      width: 72px;
      height: 64px;
      position: relative;
      margin-bottom: 5px;

      &:before {
        @extend %before-after;
        background: url(../images/icon-auth-google.svg) no-repeat 0 0;
        width: 110px;
        height: 86px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        transition: all 0.5s;
        z-index: 2;
      }

      &:after {
        @extend %before-after;
        background: url(../images/icon-auth-google-hover.svg) no-repeat 0 0;
        width: 110px;
        height: 86px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        opacity: 0;
        transition: all 0.5s;
        margin-left: 1px;
        z-index: 3;
      }

      .top {
        background: url(../images/icon-auth-google-item.svg) no-repeat 0 0;
        width: 62px;
        height: 48px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(27px, -21px);
        transition: all 0.5s;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }

        .top {
          transform: translate(31px, -26px);
        }
      }
    }

    .fb {
      width: 60px;
      height: 55px;
      position: relative;

      &:before {
        @extend %before-after;
        background: url(../images/icon-auth-fb.svg) no-repeat 0 0;
        width: 130px;
        height: 121px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        transition: all 0.5s;
      }

      &:after {
        @extend %before-after;
        background: url(../images/icon-auth-fb-hover.svg) no-repeat 0 0;
        width: 141px;
        height: 133px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        margin-left: 2px;
        opacity: 0;
        transition: all 0.5s;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.open-cases-btns-wrap {
  display: flex;
  justify-content: center;
  position: relative;
}

.open-cases-btns {
  text-align: center;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 110px;

  a {
    margin-right: 2em;
    &:before {
      pointer-events: none;
    }
  }

  .advantages-sign-in-steam {
    width: 262px;
    height: 76px;
    position: relative;
    text-decoration: none;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(255, 198, 0, 1) 0%,
      rgba(57, 2, 78, 0.39) 68%,
      rgba(57, 2, 78, 0) 70%
    );
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(255, 198, 0, 1) 0%,
      rgba(57, 2, 78, 0.39) 68%,
      rgba(57, 2, 78, 0) 70%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(255, 198, 0, 1) 0%,
      rgba(57, 2, 78, 0.39) 68%,
      rgba(57, 2, 78, 0) 70%
    );

    &:before {
      @extend %before-after;
      background: url(../images/icon-auth-steam-empty.svg) no-repeat 0 0;
      width: 511px;
      height: 305px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      transition: transform 0.5s;
      filter: drop-shadow(0px 8px 64px rgba(255, 122, 0, 0.54));
      margin-top: -10px;
    }

    &:after {
      @extend %before-after;
      background: url(../images/icon-auth-steam-empty-hover.svg) no-repeat 0 0;
      width: 511px;
      height: 305px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      transition: transform 0.5s;
      filter: drop-shadow(0px 8px 64px rgba(255, 122, 0, 0.64));
      margin-top: -10px;
    }

    .top {
      position: absolute;
      background: url(../images/icon-auth-steam-2-top.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 61px;
      height: 20px;
      top: -33px;
      left: 19px;
      pointer-events: none;
    }

    .bot-1 {
      position: absolute;
      background: url(../images/icon-auth-steam-2-bot.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 26px;
      height: 16px;
      bottom: -16px;
      left: 6px;
      pointer-events: none;
    }

    .bot-2 {
      position: absolute;
      background: url(../images/icon-auth-steam-2-bot-2.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 73px;
      height: 13px;
      bottom: -9px;
      left: 129px;
      pointer-events: none;
    }

    &:hover {
      .top {
        transform: translate(-5px, -5px);
      }

      .bot-1 {
        transform: translate(-15px, 5px);
      }

      .bot-2 {
        transform: translate(-5px, 5px);
      }

      &:after {
        opacity: 1;
      }

      &:after,
      &:before {
        transform: translate(-51%, -51%);
      }

      .small-text,
      .text {
        transform: translate(-4px, -4px);
      }
    }

    .small-text {
      font-size: 17px;
      color: #672900;
      position: relative;
      font-weight: 500;
      padding: 8px 0 0 50px;
      z-index: 3;
      transition: all 0.5s;
    }

    .text {
      position: relative;
      z-index: 3;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 95px;
      color: #672900;
      transition: all 0.5s;
    }
  }

  .advantages-sign-in-vk {
    width: 99px;
    height: 76px;
    position: relative;
    margin: 0 20px 0 15px;

    &:before {
      @extend %before-after;
      background: url(../images/icon-auth-vk-2.svg) no-repeat 0 0;
      width: 345px;
      height: 311px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      transition: transform 0.5s;
    }

    &:after {
      @extend %before-after;
      background: url(../images/icon-auth-vk-2-hover.svg) no-repeat 0 0;
      width: 345px;
      height: 311px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      transition: transform 0.5s;
    }

    .top {
      position: absolute;
      background: url(../images/icon-auth-vk-2-top.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 73px;
      height: 13px;
      top: -22px;
      left: 34px;
      pointer-events: none;
    }

    .bot-1 {
      position: absolute;
      background: url(../images/icon-auth-vk-2-bot.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 33px;
      height: 26px;
      bottom: -5px;
      left: -6px;
      pointer-events: none;
    }

    &:hover {
      .top {
        transform: translate(-5px, -5px);
      }

      .bot-1 {
        transform: translate(-5px, 5px);
      }

      &:after {
        opacity: 1;
      }

      &:after,
      &:before {
        transform: translate(-49.5%, -51%);
      }
    }
  }

  .advantages-sign-in-fb {
    width: 93px;
    height: 76px;
    position: relative;
    top: -5px;
    left: -6px;
    margin-left: 26px;
    margin-right: 30px;

    &:before {
      @extend %before-after;
      background: url(../images/icon-auth-fb-2.svg) no-repeat 0 0;
      width: 358px;
      height: 336px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.5s;
    }

    &:after {
      @extend %before-after;
      background: url(../images/icon-auth-fb-2-hover.svg) no-repeat 0 0;
      width: 358px;
      height: 336px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      transition: transform 0.5s;
    }

    .top {
      position: absolute;
      background: url(../images/icon-auth-fb-2-top.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 100px;
      height: 72px;
      top: -42px;
      left: -6px;
      pointer-events: none;
    }

    .bot-1 {
      position: absolute;
      background: url(../images/icon-auth-fb-2-bot.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 102px;
      height: 87px;
      bottom: -44px;
      left: 16px;
      pointer-events: none;
    }

    &:hover {
      .top {
        transform: translate(-5px, -5px);
      }

      .bot-1 {
        transform: translate(-5px, 5px);
      }

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }

      &:after,
      &:before {
        transform: translate(-49%, -50%);
      }
    }
  }

  .advantages-sign-in-google {
    width: 93px;
    height: 76px;
    position: relative;
    top: -5px;
    left: -6px;

    &:before {
      @extend %before-after;
      background: url(../images/icon-auth-google-2.svg) no-repeat 0 0;
      width: 252px;
      height: 224px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.5s;
    }

    &:after {
      @extend %before-after;
      background: url(../images/icon-auth-google-2-hover.svg) no-repeat 0 0;
      width: 252px;
      height: 224px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      transition: transform 0.5s;
    }

    .top {
      position: absolute;
      background: url(../images/icon-auth-google-2-top.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 30px;
      height: 21px;
      top: -22px;
      left: 10px;
      pointer-events: none;
    }

    .bot {
      position: absolute;
      background: url(../images/icon-auth-google-2-bot.svg) no-repeat 0 0;
      transition: all 0.5s;
      width: 23px;
      height: 22px;
      bottom: -14px;
      left: -6px;
      pointer-events: none;
    }

    &:hover {
      .top {
        transform: translate(-10px, -4px);
      }

      .bot {
        transform: translate(-2px, 1px);
      }

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }

      &:after,
      &:before {
        transform: translate(-49%, -52%);
      }
    }
  }

  .btns-shadow {
    position: absolute;
    width: 345px;
    height: 128px;
    background: radial-gradient(
      100% 100% at 0% 0%,
      #68a1ba 0%,
      rgba(157, 226, 255, 0) 100%
    );
    opacity: 0.2;
    right: 40px;
    top: -36px;
    transform: rotate(-180deg) skewX(-9deg);
    pointer-events: none;
  }

  .btns-shadow-2 {
    width: 345px;
    height: 126px;
    background: radial-gradient(
      100% 100% at 0% 100%,
      #2194ff 0%,
      rgba(33, 148, 255, 0) 100%
    );
    opacity: 0.2;
    top: -35px;
    right: -323px;
    position: absolute;
    transform: skewX(-9deg);
    pointer-events: none;
  }
}

.btn,
.case-cost,
.open-case {
  height: 36px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.btn,
.open-case {
  min-width: 202px;
  line-height: 35px;
  border-radius: 25px;
  border: 2px solid #fff;
}

.btn span:not(.fa),
.open-case span:not(.fa) {
  float: right;
  display: inline-block;
  border-radius: 0 25px 25px 0;
  padding: 0 0 0 20px;
  font-weight: 400;
}

.btn span.ant-btn-loading-icon,
.btn span.ant-btn-loading-icon .anticon {
  float: none;
  background: transparent;
  border-radius: 0;
  padding: 0;
}

.case-play-win-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  a,
  button {
    margin: 0 12px;
  }
}

.btn.btn-sell {
  border: 4px solid #06c50c;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#019101 0%, #008200 100%);
  transition: all 0.5s;
  position: relative;

  &:before {
    @extend %before-after;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #00f308;
    opacity: 0;
    border-radius: 30px;
    transition: all 0.3s;
  }

  svg {
    margin: 0 12px 0 8px;
  }

  span:not(.fa) {
    background: none;
  }

  .fa {
    position: relative;
    top: 2px;
    margin-left: 5px;
  }

  .text {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;
    color: #fff;
    text-shadow: 0px 0px 6px #f7c0fe;
    transition: all 0.5s;

    &:not(.fa) {
      font-weight: 700;
    }
  }

  .cost {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 6px);
    margin: 0px 4px 0px 20px;
    background: rgb(3, 86, 3);
    background: -moz-linear-gradient(
      top,
      rgba(3, 86, 3, 1) 0%,
      rgba(0, 45, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(3, 86, 3, 1) 0%,
      rgba(0, 45, 0, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(3, 86, 3, 1) 0%,
      rgba(0, 45, 0, 1) 100%
    );
    border-radius: 4px 26px 26px 4px;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 24px;
    color: #fff;
    text-shadow: 0px 0px 6px rgba(211, 44, 230, 0);
    min-width: 80px;
    padding: 0 10px;
    font-weight: 700;

    .fa {
      font-weight: 700;
    }
  }

  &:hover {
    background: linear-gradient(#00a400 0%, #009f00 100%);
    border-color: #00f308;

    &:before {
      opacity: 1;
    }

    .text {
      text-shadow: 0px 0px 8px #fff;
    }
  }
}

.btn-pay {
  width: 320px;
  height: 80px;
  position: relative;
  display: flex;
  margin: 34px auto;

  &:before {
    @extend %before-after;
    background: url(../images/bg-btn-pay-empty-2.svg) no-repeat 0 0;
    width: 479px;
    height: 234px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.1s;
    z-index: 2;
    pointer-events: none;
  }

  &:after {
    @extend %before-after;
    background: url(../images/bg-btn-pay-empty-2-hover.svg) no-repeat 0 0;
    width: 519px;
    height: 274px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.1s;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
  }

  .top,
  .top-2,
  .bot,
  .bot-2 {
    position: absolute;
    transition: all 0.4s;
    pointer-events: none;
  }

  .top {
    top: -17px;
    left: -23px;
    background: url(../images/bg-btn-pay-top.svg) no-repeat 0 0;
    width: 48px;
    height: 14px;
  }

  .top-2 {
    top: -13px;
    right: -12px;
    background: url(../images/bg-btn-pay-top-2.svg) no-repeat 0 0;
    width: 22px;
    height: 13px;
  }

  .bot {
    bottom: -15px;
    left: -2px;
    background: url(../images/bg-btn-pay-bot.svg) no-repeat 0 0;
    width: 22px;
    height: 20px;
  }

  .bot-2 {
    bottom: 2px;
    right: -12px;
    background: url(../images/bg-btn-pay-bot-2.svg) no-repeat 0 0;
    width: 46px;
    height: 20px;
  }

  .girl {
    position: absolute;
    top: -250px;
    right: -265px;
    background: url(../images/bg-girl.svg) no-repeat 0 0;
    transition: all 0.5s;
    width: 236px;
    height: 710px;
    pointer-events: none;
  }

  .girl-2 {
    position: absolute;
    top: -250px;
    right: -265px;
    background: url(../images/bg-girl-hover.svg) no-repeat 0 0;
    transition: all 0.5s;
    width: 236px;
    height: 710px;
    opacity: 0;
    pointer-events: none;
  }

  .shadow,
  .shadow-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(../images/shadow.svg) no-repeat 0 0;
    transition: all 0.5s;
    width: 462px;
    height: 148px;
    pointer-events: none;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(86.85deg, #6b2100 4.03%, #3e1d00 97.39%),
      #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    z-index: 3;
    margin-top: -10px;
    margin-left: 20px;
  }

  .shadow-2 {
    background: url(../images/shadow-hover.svg) no-repeat 0 0;
    opacity: 0;
    transition: all 0.5s;
  }

  &:hover {
    .top {
      left: -25px;
      top: -16px;
    }

    .top-2 {
      right: -18px;
      top: -16px;
    }

    .bot {
      bottom: -22px;
      left: -12px;
    }

    .bot-2 {
      bottom: -10px;
      right: -23px;
    }

    &:before,
    .girl,
    .shadow {
      opacity: 0;
    }

    &:after,
    .girl-2,
    .shadow-2 {
      opacity: 1;
    }
  }
}

a[disabled] {
  opacity: 0.7;
  pointer-events: none;
}

a.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.btn-back {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .icon {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: -moz-linear-gradient(
      left,
      rgba(123, 123, 123, 0.09) 0%,
      rgba(4, 8, 23, 0.2) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(123, 123, 123, 0.09) 0%,
      rgba(4, 8, 23, 0.2) 100%
    );
    background: linear-gradient(
      to right,
      rgba(123, 123, 123, 0.09) 0%,
      rgba(4, 8, 23, 0.2) 100%
    );
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 4px solid rgba(75, 105, 255, 0.32);
    transition: all 0.5s;

    &:before {
      @extend %before-after;
      border: 3px solid transparent;
      transition: all 0.3s;
      border-radius: 50%;
      width: 58px;
      height: 58px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
    }

    svg {
      opacity: 0.5;
      transition: all 0.5s;
    }
  }

  span {
    position: absolute;
    bottom: -17px;
    left: 50%;
    transform: translate(-50%, -70%);
    font-size: 14px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 20px;
    background: rgb(61, 89, 229);
    background: -moz-linear-gradient(
      left,
      rgba(61, 89, 229, 1) 0%,
      rgba(27, 46, 143, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(61, 89, 229, 1) 0%,
      rgba(27, 46, 143, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(61, 89, 229, 1) 0%,
      rgba(27, 46, 143, 1) 100%
    );
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    border-color: #4b69ff;

    .icon {
      background: -moz-linear-gradient(left, #00178a 0%, #001060 100%);
      background: -webkit-linear-gradient(left, #00178a 0%, #001060 100%);
      background: linear-gradient(to right, #00178a 0%, #001060 100%);
      border-color: #4b69ff;

      svg {
        opacity: 1;
      }

      &:before {
        border-color: #4b69ff;
      }
    }

    span {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  &.btn-contracts {
    .icon {
      border-color: #8847ff;
      background: -moz-linear-gradient(left, #7d36ff 0%, #3900a1 100%);
      background: -webkit-linear-gradient(left, #7d36ff 0%, #3900a1 100%);
      background: linear-gradient(to right, #7d36ff 0%, #3900a1 100%);

      svg {
        opacity: 1;
      }
    }

    span {
      background: -moz-linear-gradient(left, #7d36ff 0%, #3900a1 100%);
      background: -webkit-linear-gradient(left, #7d36ff 0%, #3900a1 100%);
      background: linear-gradient(to right, #7d36ff 0%, #3900a1 100%);
    }

    &:hover {
      .icon {
        &:before {
          border-color: #8847ff;
        }
      }
    }
  }

  &.btn-home {
    span {
      background: -moz-linear-gradient(left, #8847ff 0%, #4e1fa5 100%);
      background: -webkit-linear-gradient(left, #8847ff 0%, #4e1fa5 100%);
      background: linear-gradient(to right, #8847ff 0%, #4e1fa5 100%);
    }

    &:hover {
      .icon {
        border-color: #8847ff;
        background: -moz-linear-gradient(left, #45008a 0%, #3d0060 100%);
        background: -webkit-linear-gradient(left, #45008a 0%, #3d0060 100%);
        background: linear-gradient(to right, #45008a 0%, #3d0060 100%);

        &:before {
          border-color: #8847ff;
        }

        svg {
          opacity: 1;

          path {
            fill: #8847ff;
          }
        }
      }

      span {
        background: -moz-linear-gradient(left, #8847ff 0%, #4e1fa5 100%);
        background: -webkit-linear-gradient(left, #8847ff 0%, #4e1fa5 100%);
        background: linear-gradient(to right, #8847ff 0%, #4e1fa5 100%);
      }
    }
  }
}

.rub {
  margin-left: 5px;
  font-style: normal;
}

.btn-upgrade-from {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: linear-gradient(#2c007b 0%, #250068 100%);
  border: 2px solid #8f00e1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-pay-cost-wrap {
  width: 394px;
  margin: 0 auto;
}

.btn-pay-cost {
  display: flex;
  align-items: center;
  text-decoration: none;
  background: #300c47;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px #d32ce6;
  border-radius: 18px;
  color: #fff;
  height: 36px;
  position: relative;
  transition: all 0.5s;

  &:before {
    @extend %before-after;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 2px solid #fff;
    border-radius: 18px;
    opacity: 0;
    transition: all 0.5s;
  }

  .icon {
    margin-right: 8px;
    margin-left: 8px;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.56);
    margin-right: 12px;
  }

  .cost {
    height: 28px;
    background: linear-gradient(180deg, #9503a5 0%, #490051 100%);
    border-radius: 4px 14px 14px 4px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;

    svg {
      margin-right: 8px;
    }
  }

  &:hover {
    .title {
      text-shadow: 0px 0px 8px #ffffff;
    }

    &:before {
      opacity: 1;
    }
  }

  &__big {
    color: #d32ce6;
    border: 4px solid #ffffff;
    height: 64px;
    border-radius: 32px;

    .title {
      color: #d32ce6;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1px;
      text-shadow: 0px 0px 1px rgba(211, 44, 230, 0.0001);
      margin: 0 auto;
      transition: all 0.5s;
    }

    .icon {
      font-size: 30px;
      line-height: 30px;
      margin-right: 0;
      margin-left: auto;
    }

    .cost {
      height: 50px;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.9px;
      color: #ffffff;
      text-shadow: 0px 0px 6px rgba(211, 44, 230, 0.0001);
      border-radius: 4px 32px 32px 4px;
      padding: 0 16px 0 16px;

      svg {
        margin-left: 5px;
        margin-right: 0;
      }
    }

    &:before {
      border-radius: 32px;
    }

    &:hover {
      box-shadow: 0px 0px 8px #d32ce6;

      .title {
        text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6,
          1px 1px 0 #d32ce6, 0px 0px 10px #d32ce6;
        color: #ffffff;
      }
    }
  }
}

.btn-sale-tooltipe {
  position: absolute;
  background-color: rgba(235, 75, 75, 0.2);
  border-radius: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  padding: 0 10px;
  white-space: nowrap;
  left: -17px;
  top: -40px;
  display: flex;
  align-items: center;
}

.btn-sale-tooltipe:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -3px;
  width: calc(100% + 6px);
  height: 2px;
  background-color: rgb(238, 83, 67);
  transform: rotate(-16deg);
  z-index: 2;
}

.badge-cost {
  background: linear-gradient(90deg, #26003e 0%, #230170 100%);
  border-radius: 12px;
  box-shadow: 0 0 0 4px rgba(40, 4, 161, 0.24);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 0 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.blue {
    background: linear-gradient(90deg, #002e87 0%, #0652ff 100%);
    box-shadow: 0 0 0 2px #6595ff;
  }

  &.purpule {
    background: linear-gradient(90deg, #490475 0%, #380b9d 100%), #c4c4c4;
    box-shadow: 0 0 0 2px #6804a1;
  }

  &.green {
    background: linear-gradient(90deg, #005700 0%, #089408 100%);
    box-shadow: 0 0 0 2px #06c50c;
  }
}

.load-more {
  display: flex;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto 64px auto;
}

.btn-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px 0 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #d32ce6;
  background: rgba(211, 44, 230, 0.2);
  transition: all 0.5s;
  box-shadow: inset 0 0 0 2px rgba(211, 44, 230, 0.4);
  border-radius: 18px;
  text-decoration: none;
  height: 36px;

  .icon {
    margin-right: 8px;
  }

  &:hover {
    background: rgba(211, 44, 230, 0.4);
    color: #fff;
    box-shadow: inset 0 0 0 3px #d32ce6;
  }

  &:active {
    background: rgba(211, 44, 230, 0.2);
    box-shadow: inset 0 0 0 3px rgba(211, 44, 230, 0.4);
    color: rgba(211, 44, 230, 0.4);
  }
}

.btn-pink {
  height: 36px;
  border-radius: 18px;
  background: linear-gradient(#7800a2 0%, #b111c4 100%);
  border: 2px solid #c20bd9;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  transition: all 0.5s;
  padding: 0 13px;

  &:hover {
    background: linear-gradient(#8e00bf 0%, #c50adb 100%);
    box-shadow: inset 0 0 0 2px #d90af3;
  }

  &:active {
    background: linear-gradient(#5b007a 0%, #6f0095 100%);
    border: 2px solid #52006e;
    box-shadow: inset 0 0 0 2px #52006e;
    color: #3a004e;
  }
}

.b-violet {
  border-radius: 18px;
  background: rgba(211, 44, 230, 0.2);
  border: 2px solid rgba(211, 44, 230, 0.4);
  transition: all 0.5s;
  padding: 4px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #d32ce6;

  &:hover {
    background: rgba(211, 44, 230, 0.4);
    box-shadow: inset 0 0 0 2px #d32ce6;
    border-color: #d32ce6;
    color: #fff;
  }

  &:active {
    background: rgba(211, 44, 230, 0.2);
    box-shadow: inset 0 0 0 2px rgba(211, 44, 230, 0.4);
    border-color: rgba(211, 44, 230, 0.4);
    color: rgba(211, 44, 230, 0.4);
  }

  &-light {
    background: linear-gradient(to right, #7800a2 0%, #b111c4 100%);
    border: 2px solid #c20bd9;
    color: #fff;

    &:hover {
      background: linear-gradient(to right, #8e00bf 0%, #c50adb 100%);
      box-shadow: inset 0 0 0 2px #d90af3;
      border-color: #d90af3;
    }

    &:active {
      background: linear-gradient(to right, #5b007a 0%, #6f0095 100%);
      box-shadow: inset 0 0 0 2px #52006e;
      border-color: #52006e;
      color: #3a004e;
    }
  }

  &-blue {
    background: rgba(75, 105, 255, 0.2);
    border: 2px solid rgba(75, 105, 255, 0.4);
    color: #4b69ff;

    &:hover {
      border-radius: 18px;
      background: rgba(75, 105, 255, 0.4);
      border-color: #4b69ff;
      box-shadow: inset 0 0 0 2px #4b69ff;
    }

    &:active {
      border-color: rgba(75, 105, 255, 0.4);
      background: rgba(75, 105, 255, 0.2);
      box-shadow: inset 0 0 0 2px rgba(75, 105, 255, 0.4);
      color: rgba(75, 105, 255, 0.4);
    }
  }
}

.btn-pink.btn-big {
  border: 4px solid #ec48ff;
  box-shadow: 0px 0px 16px #d32ce6;
  background: rgba(211, 44, 230, 0.4);
  border-radius: 32px;
  height: 64px;
  width: 100%;
  justify-content: center;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  &:hover {
    background: rgba(211, 44, 230, 0.64);
  }

  .timer {
    span {
      padding: 0;
    }
  }
}

.btn-blue,
.btn-yellow {
  padding: 10px 22px;
  height: 64px;
  background: rgba(255, 198, 0, 0.2);
  border: 4px solid #ffc600;
  box-shadow: 0px 0px 16px rgba(255, 198, 0, 0.64);
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
  transition: background 0.3s;
  position: relative;

  @media(max-width: 480px) {
    font-size: 14px;
  }
  &:hover {
    background: rgba(255, 198, 0, 0.48);
    border: 6px solid #ffc600;

    .btn-sale-cost-wrap {
      top: -19px;
      right: -8px;
    }
  }

  .btn-sale-cost-wrap {
    top: -17px;
    right: -6px;
  }

  .btn-sale-cost {
    padding: 2px 8px;
    height: 24px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    border-radius: 14px;

    &:before {
      height: 2px;
    }

    &:after {
      height: 2px;
      box-shadow: 0px 0px 3px #ff6262;
      border-radius: 1px;
    }

    &.percent {
      border-radius: 14px;
    }
  }

  &.btn-price {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      flex-grow: 1;
    }

    .cost {
      height: 50px;
      background: linear-gradient(
        180deg,
        rgba(3, 0, 11, 0.52) 0%,
        rgba(23, 21, 28, 0.52) 100%
      );
      border-radius: 4px 25px 25px 4px;
      padding: 13px 24px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.9px;
      color: #ffffff;
      margin-left: auto;
    }

    &:hover {
      .text {
        margin: 0 2px 0 0;
      }

      .cost {
        margin-right: -2px;
      }
    }
  }
}

.btn-blue {
  background: rgba(75, 105, 255, 0.2);
  border: 4px solid #4b69ff;
  box-shadow: 0px 0px 16px rgba(75, 105, 255, 0.64);

  &:hover {
    background: rgba(75, 105, 255, 0.48);
    border: 6px solid #4b69ff;
    box-shadow: 0px 0px 16px rgba(75, 105, 255, 0.64);
  }
}

.btn-buy {
  display: flex;
  align-items: center;
  height: 64px;
  background: rgba(75, 105, 255, 0.2);
  box-shadow: inset 0 0 0 4px #4b69ff, 0px 0px 16px rgba(75, 105, 255, 0.64);
  border-radius: 32px;
  color: #ffffff;
  margin: 0 28px;
  transition: all 0.3s;
  position: relative;
  padding: 1px 6px;
  font-family: 'Roboto', sans-serif;

  &-text {
    padding: 0 20px 0 32px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
  }

  &-cost {
    padding: 0 24px;
    background: linear-gradient(
      180deg,
      rgba(3, 0, 11, 0.52) 0%,
      rgba(23, 21, 28, 0.52) 100%
    );
    border-radius: 4px 25px 25px 4px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.9px;
    text-shadow: -1px -1px 0 rgba(75, 105, 255, 0.0001),
      1px -1px 0 rgba(75, 105, 255, 0.0001),
      -1px 1px 0 rgba(75, 105, 255, 0.0001),
      0px 0px 6px rgba(211, 44, 230, 0.0001);
    height: calc(100% - 12px);
    margin-right: 1px;
    display: flex;
    align-items: center;
  }

  &:hover {
    background: rgba(75, 105, 255, 0.48);
    box-shadow: inset 0 0 0 6px #4b69ff, 0px 0px 16px rgba(75, 105, 255, 0.64);
    color: #fff;
  }

  &.btn-icon {
    .icon {
      font-size: 32px;
      line-height: 32px;
      margin-left: 14px;
    }

    .btn-buy-text {
      padding: 0 31px;
    }

    .btn-buy-cost {
      padding: 0 16px;
      white-space: nowrap;
    }
  }

  .btn-sale-cost-wrap {
    position: absolute;
    top: -11px;
    right: -6px;
    display: flex;
    flex-direction: row-reverse;

    .btn-sale-cost {
      padding: 2px 8px;
      height: 24px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      border-radius: 14px;

      &:after {
        height: 2px;
        margin-top: 0px;
        border-radius: 1px;
      }
    }
  }
}

.advantages-sign-in-play-demo {
  width: 270px;
  height: 76px;
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0px -2px 0px rgba(0, 12, 43, 0.48);
  position: relative;
  text-decoration: none;
  margin-top: -7px;

  &:after,
  &:before {
    @extend %before-after;
    width: 426px;
    height: 239px;
    background: url(../images/btn-play-demo.svg) no-repeat 0 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    pointer-events: none;
  }

  &:after {
    background: url(../images/btn-play-demo-hover.svg) no-repeat 0 0;
    opacity: 0;
  }

  .text {
    margin-top: -10px;
  }

  .top {
    @extend %before-after;
    width: 47px;
    height: 25px;
    background: url(../images/btn-play-demo-top.svg) no-repeat 0 0;
    right: 10px;
    top: -27px;
    transition: all 0.3s;
  }

  .bot {
    @extend %before-after;
    width: 62px;
    height: 25px;
    background: url(../images/btn-play-demo-bot.svg) no-repeat 0 0;
    left: 0;
    bottom: -15px;
    transition: all 0.3s;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }

    .top {
      top: -29px;
      right: 5px;
    }

    .bot {
      left: -5px;
      bottom: -17px;
    }
  }
}

.btn-fast-open {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  color: rgba(213, 185, 255, 0.4);
  display: flex;
  align-items: center;
  margin-left: 32px;
  padding-left: 16px;
  max-height: 64px;

  @media (max-width: 1280px) {
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    padding-left: 0;
  }

  span {
    padding-right: 20px;
    max-width: 120px;

    @media (max-width: 1280px) {
      max-width: 100%;
      br {
        display: none;
      }
    }
  }

  .switch {
    display: inline-block;
    height: 36px;
    position: relative;
    width: 52px;

    input {
      display: none;
    }

    .switch-round {
      background: rgba(10, 1, 12, 0.4);
      border: 2px solid rgba(136, 71, 255, 0.16);
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        background: rgba(136, 71, 255, 0.16);
        bottom: 4px;
        content: '';
        height: 24px;
        left: 3px;
        position: absolute;
        transition: 0.4s;
        width: 24px;
        border-radius: 50%;
      }
    }

    input:checked + .switch-round {
      background: rgba(10, 1, 12, 0.4);
      border: 2px solid #3b0274;
      box-shadow: none;

      &:before {
        background: #d32ce6;
        box-shadow: 0px 0px 12px rgba(211, 44, 230, 0.639);
        transform: translateX(17px);
      }
    }

    &:hover {
      .switch-round {
        background: rgba(10, 1, 12, 0.4);
        box-shadow: inset 0 0 0 1px rgba(136, 71, 255, 0.16);

        &:before {
          background: rgba(136, 71, 255, 0.4);
        }
      }
    }
  }
}

.back-to-wrap {
  display: flex;
  justify-content: center;
}

.back-to {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #c131e4;
  text-decoration: none;
  text-transform: uppercase;

  .icon {
    width: 40px;
    height: 40px;
    background: rgba(230, 155, 255, 0.04);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(230, 155, 255, 0.4);
    margin-right: 20px;
    position: relative;
  }

  .tooltip-block {
    font-family: 'Roboto', sans-serif;
    background: rgba(230, 155, 255, 0.08);
    z-index: 2;
  }

  &:hover {
    .icon {
      background: linear-gradient(
          0deg,
          rgba(230, 155, 255, 0.12),
          rgba(230, 155, 255, 0.12)
        ),
        #170041;
      color: #f7dbff;

      .tooltip-block {
        opacity: 1;
      }
    }
  }
}

.btn-blue-sec {
  height: 64px;
  background: rgba(75, 105, 255, 0.2);
  box-shadow: inset 0 0 0 4px #4b69ff, 0px 0px 16px rgba(75, 105, 255, 0.64);
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
  width: 100%;
  transition: all 0.3s;
  position: relative;

  .loader-item {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background: rgba(75, 105, 255, 0.48);
    box-shadow: inset 0 0 0 6px #4b69ff, 0px 0px 16px rgba(75, 105, 255, 0.64);
  }

  &.disabled {
    background: rgba(136, 71, 255, 0.2);
    box-shadow: inset 0 0 0 4px rgba(136, 71, 255, 0.2);
    pointer-events: none;
    color: rgba(255, 255, 255, 0.32);
    text-shadow: none;
  }
}

.btn-get {
  display: inline-flex;
  align-items: center;
  height: 64px;
  background: rgba(176, 195, 217, 0.2);
  box-shadow: inset 0 0 0 4px #b0c3d9, 0px 0px 16px rgb(176 195 217 / 64%);
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  padding: 7px;
  font-family: 'Roboto', sans-serif;
  transition: all 0.3s;

  .text {
    padding: 5px 20px 5px 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
    height: 50px;
    display: flex;
    align-items: center;
  }

  .cost {
    display: flex;
    align-items: center;
    padding: 5px 15px 5px 20px;
    background: linear-gradient(
      180deg,
      rgba(3, 0, 11, 0.52) 0%,
      rgba(23, 21, 28, 0.52) 100%
    );
    border-radius: 4px 25px 25px 4px;
    height: 50px;
    line-height: 24px;
    letter-spacing: 0.9px;
    color: #ffffff;
    -webkit-text-stroke: 1px rgba(211, 44, 230, 0.0001);
    text-shadow: 0px 0px 6px rgba(211, 44, 230, 0.0001);
  }

  &:hover {
    background: rgba(176, 195, 217, 0.48);
    box-shadow: inset 0 0 0 6px #b0c3d9, 0px 0px 16px rgba(176, 195, 217, 0.64);
  }
}

.btn-open {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  background: rgba(6, 197, 12, 0.2);
  box-shadow: inset 0 0 0 4px #06c50c, 0px 0px 16px rgba(6, 197, 12, 0.64);
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px 32px;
  transition: all 0.3s;

  &:hover {
    background: rgba(6, 197, 12, 0.48);
    box-shadow: inset 0 0 0 6px #06c50c, 0px 0px 16px rgba(6, 197, 12, 0.64);
  }

  &.disabled {
    background: rgba(136, 71, 255, 0.2);
    box-shadow: inset 0 0 0 4px rgba(136, 71, 255, 0.2);
    color: rgba(255, 255, 255, 0.32);
    pointer-events: none;
    cursor: default;
  }
}

.btn-coin-cost {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  padding: 2px 4px 2px 8px;
  height: 24px;
  background: linear-gradient(90deg, #26003e 0%, #230170 100%);
  box-shadow: 0 0 0 4px rgba(40, 4, 161, 0.24);
  border-radius: 12px;
  margin-left: 2px;

  svg {
    margin-left: 4px;
  }
}

.btn-hoykey {
  position: absolute;
  top: -13px;
  left: -4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(33, 0, 54, 0.5) 0%,
    rgba(7, 0, 23, 0.5) 100%
  );
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.8;
  text-transform: uppercase;
  border: 2px solid rgba(255, 255, 255, 0.2);
  z-index: 3;

  @media(max-width: 1024px) {
    display: none;
  }
}

.btn-d3 {
  width: 24px;
  height: 24px;
  background: rgba(230, 155, 255, 0.04);
  backdrop-filter: blur(8px);
  border-radius: 40px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  text-decoration: none;
  @include flex(false, center, center, false);

  .icon {
    font-size: 16px;
    line-height: 16px;
    color: rgba(213, 185, 255, 0.8);
  }

  .tooltip-block {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(230, 155, 255, 0.12),
        rgba(230, 155, 255, 0.12)
      ),
      #170041;
    z-index: 3;

    .icon {
      color: #f7dbff;
    }

    .tooltip-block {
      opacity: 1;
    }
  }
}

.btn-game {
  @include inline-flex(row, center, false, false);
  padding: 12px 32px 12px 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #4b69ff;
  height: 64px;
  border-radius: 32px;
  box-shadow: inset 0 0 0 4px rgba(75, 105, 255, 0.2);
  text-decoration: none;
  transition: box-shadow 0.3s;
  width: 320px;
  position: relative;

  .icon {
    font-size: 32px;
    line-height: 32px;
    color: #4b69ff;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    @include flex(false, center, center, false);
  }

  .text {
    flex-grow: 1;
  }

  &:hover {
    box-shadow: inset 0 0 0 6px #323c98;
  }

  &--contracts {
    background: linear-gradient(90deg, #7d36ff 0%, #3900a1 100%);
    box-shadow: inset 0 0 0 4px #8847ff, 0px 0px 8px rgba(136, 71, 255, 0.4);
    letter-spacing: 1px;
    color: #fff;

    svg {
      width: 40px;
      height: 40px;

      path {
        fill: #fff;
      }
    }

    &:hover {
      box-shadow: inset 0 0 0 6px #8847ff, 0px 0px 8px rgba(136, 71, 255, 0.4);

      .text {
        text-shadow: -1px -1px 0 #8847ff, 1px -1px 0 #8847ff, -1px 1px 0 #8847ff,
          1px 1px 0 #8847ff, 0px 0px 8px #8847ff;
      }
    }
  }
  &--repeat {
    box-shadow: inset 0 0 0 4px rgba(230, 155, 255, 0.2);
    color: #F7DBFF;

    .icon {
      color: #F7DBFF;
    }

    &:hover {
      box-shadow: inset 0 0 0 6px rgba(230, 155, 255, 0.2);
    }
  }
}

.btn-goto-event {
  @include inline-flex(row, center, center, false);
  height: 64px;
  background: linear-gradient(180deg, rgba(255, 92, 0, 0.4) 0%, rgba(255, 92, 0, 0) 100%), linear-gradient(0deg, #FFC600, #FFC600), #13072C;
  box-shadow: 0px 6px 12px rgba(5, 0, 16, 0.5), inset 0px 2px 0px rgba(255, 239, 184, 0.4), inset 0px -3px 0px rgba(185, 65, 13, 0.5);
  border-radius: 6px;
  padding: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #13072C;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
  text-decoration: none;
  transition: all .3s;

  @media(max-width: 992px) {
    font-size: 16px;
    padding: 4px 20px 4px 4px;
    height: 48px;
  }

  .icon {
    margin-right: 16px;
    font-size: 32px;
  }

  .text {
    min-width: 232px;
    text-align: center;

    @media(max-width: 992px) {
      min-width: auto;
    }
  }

  &:hover {
    background: linear-gradient(0deg, #FFC600, #FFC600), #13072C;
  }

  &:active {
    background: linear-gradient(0deg, #FF9E01, #FF9E01), #13072C;
  }

  &-wrap {
    @include flex(column, false, false, false);
    gap: 8px;
  }
}