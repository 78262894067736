body {
  font-size: 14px;
  color: $white;
  min-width: 1366px;

  > jdiv {
    opacity: 0;
    visibility: hidden;
    transition: 0.05s ease;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 1366px) {
    min-width: auto;
  }
  @media (max-width: 992px) {
    padding-bottom: 48px;
  }

  .ant-select-dropdown {
    background-color: #363744;
    .ant-select-item {
      color: #fff;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #202028;
      color: #fff;
    }
  }
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #292929;
}

body::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 6px;
}

img,
a {
  user-select: none;
}

.wrap {
  position: relative;
  background: #131318;

  @media (max-width: 1280px) {
    //overflow: hidden;
  }

  //&:after {
  //  @extend %before-after;
  //  width: 100%;
  //  height: 160px;
  //  pointer-events: none;
  //  top: 0;
  //  left: 0;
  //  background: url(../images/bg-clound.png) no-repeat 0 0;
  //  z-index: 4;
  //}
}

// #childrenBlock {
//   min-height: calc(100vh - 241px);
// }

.scrool {
  height: 587;
  overflow: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #363744
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(173, 119, 255, 0.4);
  }
}

#root {
  overflow-x: hidden;

  @media (max-width: 1024px) {
    overflow-x: unset;
  }

}

body.overflow {
  overflow: hidden;
}

.main[data-host="hellcases.org"] {
  .header-left .logo,
  .footer .logo {
    img {
      object-position: -99999px 99999px;
      background: url(../images/logo-hellcases.png) 50% 50% no-repeat;
      background-size: 80%;
    }
  }
}

.main[data-host="justcases.org"] {
  .header-left .logo,
  .footer .logo {
    img {
      object-position: -99999px 99999px;
      background: url(../images/justcase.png) 50% 50% no-repeat;
      background-size: 60%;
    }
  }

  .footer .logo img {
    background-size: contain;
  }
}

.main[data-host="csgohappy.com"] {
  .header-left .logo,
  .footer .logo {
    img {
      object-position: -99999px 99999px;
      background: url(../images/csgohappy.com.svg) 50% 50% no-repeat;
      background-size: 60%;
    }
  }
  .footer .logo img {
    background-size: contain;
  }
}

.main[data-host="key-dropp.net"] {
  .header-left .logo,
  .footer .logo {
    img {
      object-position: -99999px 99999px;
      background: url(../images/key-dropp.png) 50% 50% no-repeat;
      background-size: 60%;
    }
  }

  .footer .logo img {
    background-size: contain;
  }
}

.links-all-pages {
  li {
    margin-bottom: 10px;
  }

  a {
    color: #fff;
  }
}

// убираем кнопку открытия видео в новом окне в опере.
#detach-button-host {
  opacity: 0 !important;
  visibility: hidden !important;
  display: none !important;
}

.main {
  .backdrop {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #292929;
    }

    &::-webkit-scrollbar-thumb {
      background: #000;
      border-radius: 6px;
    }
  }
}

.icon-gift {
  width: 16px;
  height: 16px;
  background: url(../images/icon-gift.svg) no-repeat;
  margin-right: 2px;
  display: block;
}
